import React from 'react';
import EmojiPickerReact from 'emoji-picker-react';
import styles from './EmojiPicker.module.css';

const EmojiPicker = ({ onEmojiClick, onClose }) => {
  return (
    <div className={styles.emojiPickerContainer}>
      <EmojiPickerReact
        onEmojiClick={(emojiData) => {
          onEmojiClick(emojiData.emoji);
          onClose();
        }}
        autoFocusSearch={false}
        theme="dark"
        height={400}
        width="350px"
      />
    </div>
  );
};

export default EmojiPicker;