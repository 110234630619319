import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './ThreadSidebar.module.css';
import MessageView from '../../Message/MessageView';
import TextBoxView from '../../TextBox/TextBoxView';
import useSidebarStore from '../../../store/sidebarStore';

const shouldNestMessage = (currentMsg, prevMsg) => {
  if (!prevMsg || currentMsg.sender_id !== prevMsg.sender_id) {
    return false;
  }
  
  const currentTime = new Date(currentMsg.created_at);
  const prevTime = new Date(prevMsg.created_at);
  const timeDiff = (currentTime - prevTime) / (1000 * 60);  
  
  return timeDiff <= 5;
};

const ThreadSidebar = ({ isOpen, onClose, parentMessage, parentRoomType, parentRoomId, parentCreatedAt }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const threadContainerRef = useRef(null);
  const { messages, fetchMessages } = useSidebarStore();

  const loadInitialMessages = useCallback(async () => {
    if (!parentMessage?.message_id) return;
    setIsLoading(true);
    await fetchMessages(parentMessage.message_id, 'thread', new Date().toISOString(), 25);
    setIsLoading(false);
    setIsInitialLoad(false);
  }, [fetchMessages, parentMessage?.message_id]);

  const loadMoreMessages = useCallback(async () => {
    const threadMessages = messages[parentMessage.message_id] || [];
    if (threadMessages.length === 0 || isLoading || hasReachedEnd) return;

    setIsLoading(true);
    const oldestMessage = threadMessages[0];
    const previousScrollHeight = threadContainerRef.current.scrollHeight;

    await fetchMessages(parentMessage.message_id, 'thread', oldestMessage.created_at, 25);

    const updatedMessages = useSidebarStore.getState().messages[parentMessage.message_id] || [];
    const newMessageCount = updatedMessages.length - threadMessages.length;

    if (newMessageCount < 25) {
      setHasReachedEnd(true);
    }

    if (threadContainerRef.current) {
      const newScrollHeight = threadContainerRef.current.scrollHeight;
      const scrollDiff = newScrollHeight - previousScrollHeight;
      threadContainerRef.current.scrollTop = scrollDiff;
    }

    setIsLoading(false);
  }, [messages, parentMessage?.message_id, isLoading, hasReachedEnd, fetchMessages]);

  const handleScroll = useCallback(() => {
    const threadContainer = threadContainerRef.current;
    if (!threadContainer || isLoading || hasReachedEnd) return;

    const { scrollTop } = threadContainer;
    if (scrollTop <= 50) {
      loadMoreMessages();
    }
  }, [isLoading, hasReachedEnd, loadMoreMessages]);

  const scrollToBottom = useCallback(() => {
    const threadContainer = threadContainerRef.current;
    if (!threadContainer) return;

    threadContainer.scrollTop = threadContainer.scrollHeight;
  }, []);

  // Initial load of messages
  useEffect(() => {
    if (parentMessage?.message_id) {
      loadInitialMessages().then(() => {
        scrollToBottom();
      });
    }
    return () => {
      setIsInitialLoad(true);
      setHasReachedEnd(false);
    };
  }, [parentMessage?.message_id, loadInitialMessages, scrollToBottom]);

  const renderThreadMessages = () => {
    const messageList = messages[parentMessage.message_id] || [];
    return messageList.map((message, index) => {
      const prevMessage = index > 0 ? messageList[index - 1] : null;
      const isNested = shouldNestMessage(message, prevMessage);
      
      return (
        <MessageView 
          key={message.message_id} 
          message={message} 
          isInThreadSidebar={true}
          isNested={isNested}
          showHeader={!isNested}
        />
      );
    });
  };

  if (!isOpen) return null;

  const threadMessages = messages[parentMessage.message_id] || [];
  const replyCount = threadMessages.length;

  return (
    <div className={styles.threadSidebar}>
      <div className={styles.header}>
        <h2>Thread</h2>
        <button onClick={onClose} className={styles.closeButton}>×</button>
      </div>
      <div className={styles.parentMessage}>
        <MessageView message={parentMessage} isInThreadSidebar={true} isParentMessage={true} />
      </div>
      {replyCount > 0 && (
        <div className={styles.replySeparator}>
          {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
        </div>
      )}
      <div className={styles.threadMessages} ref={threadContainerRef} onScroll={handleScroll}>
        {isLoading && <div className={styles.loadingIndicator}>Loading...</div>}
        {renderThreadMessages()}
      </div>
      <div className={styles.textboxContainer}>
        <TextBoxView 
          roomId={parentMessage.message_id} 
          roomType="thread" 
          parentRoomType={parentRoomType} 
          parentRoomId={parentRoomId} 
          parentCreatedAt={parentCreatedAt}
        />
      </div>
    </div>
  );
};

export default ThreadSidebar;