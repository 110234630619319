import { useEffect } from 'react';
import useLogout from '../hooks/useLogout';

const LogoutView = () => {
  const handleLogout = useLogout();

  useEffect(() => {
    handleLogout();  
  }, [handleLogout]);

  return null;
};

export { LogoutView };