import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';  
import { LoginForm, VerifyForm, sendLoginLink, verifyToken } from '../features/authentication'

import useAuthStore from '../store/authStore';

const LoginView = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  
  const { isAuthenticated, initializeAuth } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location.state?.from;
  
  useEffect(() => {
    const verifySession = async () => {
      const isValid = await initializeAuth();
      
      const hash = window.location.hash.slice(1);
      if (hash) {
        try {
          const userData = JSON.parse(atob(hash));
          if (userData.email && userData.userID) {
            window.location.hash = ''; // Clear the hash
            if (userData.redirectUrl) {
              navigate(userData.redirectUrl);
            } else {
              navigate('/');
            }
            return;
          }
        } catch (error) {
          console.error('Error parsing OAuth data:', error);
        }
      }
      
      if (isValid) {
        navigate(redirectUrl || '/', {replace: true});
      }
      setLoading(false);
    };
    verifySession();
  }, [initializeAuth, navigate, redirectUrl]);

  if (loading) {
    return null;
  }

  const handleLogin = async (email) => {
    const success = await sendLoginLink(email);
    if (success) {
      setMessage(`Not seeing email? Check spam`);
      setError('');
    } else {
      setError('Failed to send login link. Please try again.');
    }
  };

  const handleVerify = async (code) => {
    try {
      const success = await verifyToken(code);

      if (success) {
        const sessionValid = await initializeAuth();
            
        if (!sessionValid) {
          setError('Session validation failed');
          return;
        }

        if (location.state?.from?.startsWith('/invite/')) {
          navigate('/', { 
            state: { from: location.state.from },
            replace: true 
          });
        } else {
          navigate('/', { replace: true });
        }
      } else {
        setMessage('');
        setError('Invalid code. Please try again.');
      }
    } catch (error) {
      console.log('Error verifying token:', error);
      setError('Verification failed. Please try again.');
    }
  };
  
  const handleResend = () => {
    setMessage('');
    setError('');
    navigate('/login', { replace: true });
  };

  const onLoginSuccess = () => {
    const from = location.state?.from || '/';
    navigate(from, { replace: true });
  };

  return !isAuthenticated ? (
    <div>
      {!message ? (
        <LoginForm 
          email={email} 
          setEmail={setEmail} 
          handleLogin={handleLogin} 
          error={error}
          redirectUrl={location.state?.from}
        />
      ) : (
        <VerifyForm 
          handleVerify={handleVerify} 
          handleResend={handleResend} 
          message={message} 
          error={error} 
          email={email}
        />
      )}
    </div>
  ) : null;
} 

export { LoginView };