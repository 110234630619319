import React from 'react';
import TopBarView from '../TopBar/TopBarView';
import ChatSectionView from '../ChatSectionView';
import styles from './MainPanelFeed.module.css';

const MainPanelFeed = () => {
  return (
    <div className={styles.mainPanelFeed}>
      <TopBarView />
      <ChatSectionView />
    </div>
  );
};

export default MainPanelFeed;