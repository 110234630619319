const extractPublicKey = (keyPair) => {
  // Helper function to convert ArrayBuffer to base64
  const arrayBufferToBase64 = (buffer) => {
    const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
    return window.btoa(binary);
  };

  // Handle libsignal identity key pair structure
  if (keyPair.pubKey) {
    return arrayBufferToBase64(keyPair.pubKey);
  }

  // Handle libsignal signed prekey structure
  if (keyPair.signedPreKey && keyPair.signedPreKey.keyPair) {
    return arrayBufferToBase64(keyPair.signedPreKey.keyPair.pubKey);
  }

  // Handle standard key pair structure
  if (keyPair.publicKey) {
    // For Web Crypto API keys, we need to export them first
    return window.crypto.subtle.exportKey(
      "spki",
      keyPair.publicKey
    ).then(exported => {
      return arrayBufferToBase64(exported);
    });
  }

  throw new Error('Invalid key pair structure');
};

export { extractPublicKey };