import React from 'react';
import useMessageViewModel from './MessageViewModel';
import styles from './Message.module.css';
import UserDetailsModal from '../user/UserDetailsModal';
import UserQuickView from '../user/UserQuickView';
import MessageActions from './MessageActions';
import ReplyInfo from './ReplyInfo';
import LinkPreview from './linkPreview/LinkPreview';
import MessageAttachments from './messageAttachments/MessageAttachments';
import MessageReactions from './MessageReactions';

import useAuthStore from '../../store/authStore';
import useSidebarStore from '../../store/sidebarStore';
import { addMessageReaction, removeMessageReaction } from './services/reactionsService';

const MessageView = ({ message, onClick, isInThreadSidebar = false, isParentMessage = false, isNested = false, showHeader = true, isLoading = false }) => {
  const {
    avatarRef,
    userInfoRef,
    userDetails,
    displayName,
    imageUrl,
    timestamp,
    activeQuickViewUserId,
    quickViewPosition,
    isModalOpen,
    modalUserId,
    handleUserClick,
    handleMouseEnter,
    handleMouseLeave,
    closeModal,
  } = useMessageViewModel(message);

  const { userID, spaceID } = useAuthStore();
  const socket = useSidebarStore(state => state.socket);
  const currentRoom = useSidebarStore(state => state.currentRoom);

  const handleReactionClick = async (reaction) => {
    try {
      const hasReacted = message.reactions?.[reaction]?.includes(userID);
      
      if (hasReacted) {
        await removeMessageReaction(spaceID, currentRoom.room_id, message.message_id, reaction, userID, currentRoom.room_type);
        socket.emit('reaction_removed', {
          spaceId: spaceID,
          roomId: currentRoom.room_id,
          messageId: message.message_id,
          reaction,
          userId: userID
        });
      } else {
        await addMessageReaction(spaceID, currentRoom.room_id, message.message_id, reaction, userID, currentRoom.room_type);
        socket.emit('reaction_added', {
          spaceId: spaceID,
          roomId: currentRoom.room_id,
          messageId: message.message_id,
          reaction,
          userId: userID
        });
      }
    } catch (error) {
      console.error('Error handling reaction:', error);
    }
  };

  const renderMessageContent = (text) => {
    // Regular expression for both Markdown links and URLs
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const elements = [];
    const links = [];
    let lastIndex = 0;

    // First handle Markdown-style links
    text.replace(markdownLinkRegex, (match, linkText, url, index) => {
      // Add any text before the match
      if (index > lastIndex) {
        elements.push(<span key={`text-${lastIndex}`}>{text.slice(lastIndex, index)}</span>);
      }

      // Add the link
      elements.push(
        <a 
          key={`link-${index}`} 
          href={url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={styles.messageLink}
          title={url}
        >
          {linkText}
        </a>
      );
      links.push(url);
      lastIndex = index + match.length;
      return match;
    });

    // Handle any remaining text and regular URLs
    let remainingText = text.slice(lastIndex);
    remainingText.split(urlRegex).forEach((part, index) => {
      if (urlRegex.test(part)) {
        elements.push(
          <a 
            key={`url-${index}`} 
            href={part} 
            target="_blank" 
            rel="noopener noreferrer" 
            className={styles.messageLink}
            title={part}
          >
            {part}
          </a>
        );
        links.push(part);
      } else if (part) {
        elements.push(<span key={`text-${index}`}>{part}</span>);
      }
    });

    return (
      <>
        <div className={styles.messageText}>{elements}</div>
        {links.map((link, index) => (
          <LinkPreview key={`preview-${index}`} url={link} />
        ))}
      </>
    );
  };

  const shouldShowFullHeader = !isNested || message.reply_count > 0;

  return (
    <>
      <div className={`${styles.messageContainer} ${isNested && !shouldShowFullHeader ? styles.nestedMessage : ''}`}>
        {shouldShowFullHeader ? (
          <>
            <img
              ref={avatarRef}
              src={imageUrl}
              alt={`${displayName}'s avatar`}
              className={styles.userAvatar}
              onClick={handleUserClick}
              onMouseEnter={() => handleMouseEnter(avatarRef)}
              onMouseLeave={handleMouseLeave}
            />
            <div className={`${styles.container_info}`}>
              <div className={styles.messageHeader}>
                <span
                  ref={userInfoRef}
                  className={styles.userName}
                  onClick={handleUserClick}
                  onMouseEnter={() => handleMouseEnter(userInfoRef)}
                  onMouseLeave={handleMouseLeave}
                >
                  {displayName}
                </span>
                <span className={styles.timestamp}>{timestamp}</span>
              </div>
              <div className={styles.messageContent}>
                {renderMessageContent(message.message)}
                <MessageAttachments attachments={message.attachments} />
                <MessageReactions 
                  message={message} 
                  roomType={message.room_type || 'channel'} 
                  roomId={message.channel_id || message.group_id || message.dm_id}
                  handleReactionClick={handleReactionClick}
                />
              </div>
              {!isInThreadSidebar && (
                <ReplyInfo 
                  replyCount={message.reply_count} 
                  lastReplyAt={message.last_reply_at} 
                  onClick={() => onClick(message)}
                />
              )}
            </div>
          </>
        ) : (
          <div className={`${styles.container_info} ${styles.nestedContent}`}>
            <div className={styles.messageContent}>
              {renderMessageContent(message.message)}
              <MessageAttachments attachments={message.attachments} />
              <MessageReactions 
                message={message} 
                roomType={message.room_type || 'channel'} 
                roomId={message.channel_id || message.group_id || message.dm_id}
                handleReactionClick={handleReactionClick}
              />
            </div>
            {!isInThreadSidebar && (
              <ReplyInfo 
                replyCount={message.reply_count} 
                lastReplyAt={message.last_reply_at} 
                onClick={() => onClick(message)}
              />
            )}
          </div>
        )}
        
        {!isInThreadSidebar && (
          <div className={styles.messageActions}>
            <MessageActions 
              onReplyClick={() => onClick(message)} 
              isInThreadSidebar={isInThreadSidebar}
              message={message}
              onReactionSelect={(reaction) => handleReactionClick(reaction)}
            />
          </div>
        )}
      </div>

      {activeQuickViewUserId === message.sender_id && (
        <UserQuickView
          userDetails={userDetails}
          onFullProfileClick={handleUserClick}
          position={quickViewPosition}
        />
      )}
      
      {isModalOpen && modalUserId === message.sender_id && (
        <UserDetailsModal 
          userID={message.sender_id} 
          onClose={closeModal} 
          fromChat={true}
        />
      )}
    </>
  );
};

export default MessageView;