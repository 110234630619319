import imageCompression from 'browser-image-compression';

const createSpace = async (userID, spaceID, spaceName, spaceImage, spaceDescription, spacePrivacySetting) => {
  try {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('spaceID', spaceID);
    formData.append('spaceName', spaceName);
    formData.append('spaceDescription', spaceDescription);
    formData.append('spacePrivacySetting', spacePrivacySetting);

    if (spaceImage) {
      let imageBlob;
      if (spaceImage instanceof Blob || spaceImage instanceof File) {
        imageBlob = spaceImage;
      } else if (typeof spaceImage === 'string' && spaceImage.startsWith('data:')) {
        const response = await fetch(spaceImage);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      const fileName = `${spaceID}/space/profile/${Date.now()}-space-image.jpg`;
      formData.append('spaceImage', imageBlob, fileName);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/create/space`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create space");
    }

    return true;
  } catch (error) {
    console.error("Error creating space:", error);
    return false;
  }
};

const createUser = async (email, userID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/create/profile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          userID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create user");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating new user:", error);
    return false;
  }
};

const updateUser = async (email, spaceID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/update/profile`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          spaceID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating new user:", error);
    return false;
  }
};

const updateLastActiveSpace = async (email, spaceID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/update/last-active-space`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          spaceID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating new user:", error);
    return false;
  }
};

/**
 * Fetches the onboarding status for a given email address.
 *
 * Makes a GET request to the `${process.env.REACT_APP_API_URL}get-onboarding-status` endpoint with the email as a query parameter.
 *
 * @async
 * @param {string} userID - The userID to fetch the onboarding status for.
 * @returns {Promise<string>} - The onboarding status.
 * @throws {Error} - If the fetch operation fails.
 */
const getOnboardingStatus = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/get-onboarding-status?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch onboarding status");
    }

    return data.onboardingStatus;
  } catch (error) {
    console.error("Error fetching onboarding status:", error);
    throw error;
  }
};

const getLastActiveSpace = async (email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-last-active-space?email=${encodeURIComponent(
        email
      )}`
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch onboarding status");
    }

    return data.last_active_space_id;
  } catch (error) {
    console.error("Error fetching onboarding status:", error);
    throw error;
  }
};

/**
 * Updates the onboarding status for a given email address.
 *
 * Makes a POST request to the `${process.env.REACT_APP_API_URL}update-onboarding-status` endpoint with the email and status included in the request body.
 *
 * @async
 * @param {string} userID - The userID to update the onboarding status for.
 * @param {string} status - The new onboarding status to set.
 * @returns {Promise<void>}
 * @throws {Error} - If the update operation fails.
 */
const updateOnboardingStatus = async (userID, spaceID, status) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-onboarding-status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, spaceID, status }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update onboarding status");
    }
  } catch (error) {
    console.error("Error updating onboarding status:", error);
    throw error;
  }
};

const updateRole = async (userID, spaceID, userRole) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-user-role`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, spaceID, userRole }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user role 1");
    }
  } catch (error) {
    console.error("Error updating user role ", error);
    throw error;
  }
};

const getUserByEmail = async (email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/get-user-id-by-email?email=${encodeURIComponent(
        email
      )}`
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch user data");
    }

    return data.userID;
  } catch (error) {
    console.error("Error fetching user data by email:", error);
    throw error;
  }
};

const saveJoinCode = async (spaceID, userID, uniqueCode) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}space/save-space-join-code/${spaceID}/${userID}/${uniqueCode}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data.success;
};

const sendInvites = async (emails, inviteLink, workspaceKeys, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/send-invites`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emails, inviteLink, workspaceKeys, spaceID }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Server error:", errorData);
      throw new Error(errorData.error || "Failed to send invites");
    }

    const responseData = await response.json();
    console.log("Server response:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error sending invites:", error);
    throw error;
  }
};

const processJoinCode = async (joinCode, email) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/validate-space-join-code?joinCode=${encodeURIComponent(
        joinCode
      )}&email=${encodeURIComponent(email)}`
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch join code status");
    }

    return data.spaceID ? data.spaceID : null;
  } catch (error) {
    console.error("Error fetching user data by join code:", error);
    throw error;
  }
};

const fetchUserRole = async (userID, spaceID) => {
  try {  
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/get-user-role?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch role");
    } 

    return data.userRole ? data.userRole : null;
  } catch (error) {
    console.error("Error fetching role :", error);
    throw error;
  }
}; 

const updateUserSpaceDetails = async (userID, spaceID, displayName, bio, image, email) => {
  try {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('spaceID', spaceID);
    formData.append('displayName', displayName);
    formData.append('bio', bio);
    formData.append('email', email);

    if (image) {
      let imageBlob;
      if (image instanceof Blob || image instanceof File) {
        imageBlob = image;
      } else if (typeof image === 'string' && image.startsWith('data:')) {
        const response = await fetch(image);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(imageBlob, options);
      
      const fileName = `${spaceID}/members/${userID}/profile/${Date.now()}-${
        imageBlob.type === 'image/svg+xml' ? 'default-avatar.svg' : 'user-image.jpg'
      }`;
      
      formData.append('userImage', compressedFile, fileName);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-user-details`,
      {
        method: "PUT",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", response.status, errorText);
      throw new Error(`Failed to update user space details: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating user space details:", error);
    throw error;
  }
};

const getUserSpaceDetails = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-user-details?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user space details");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user space details:", error);
    return false;
  }
};

const isUserinSpace = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/is-user-in-space?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user space details");
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error fetching user space details:", error);
    return false;
  }
};

const removeSpaceInvite = async (joinCode, email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/remove-space-invite`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ joinCode, email }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to remove space invite');
    }

    return true;
  } catch (error) {
    console.error('Error removing space invite:', error);
    throw error;
  }
};

export {
  isUserinSpace,
  getUserSpaceDetails,
  updateUserSpaceDetails,
  updateRole,
  fetchUserRole,
  getOnboardingStatus,
  updateOnboardingStatus,
  getLastActiveSpace,
  createUser,
  updateUser,
  updateLastActiveSpace,
  createSpace,
  getUserByEmail,
  saveJoinCode,
  sendInvites,
  processJoinCode,
  removeSpaceInvite,
};
