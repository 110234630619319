import { useState, useRef } from 'react';
import useSidebarStore from '../../store/sidebarStore';
import useAuthStore from '../../store/authStore';
import imageCompression from 'browser-image-compression';
import { encryptMessage, decryptMessage } from '../../services/messageEncryption';

const useTextBoxViewModel = (roomId, roomType, parentRoomType, parentRoomId, parentCreatedAt) => {
  const { socket, currentRoom, isDMCreationFlowActive } = useSidebarStore();
  const { userID, spaceID } = useAuthStore();

  const { v4: uuidv4 } = require('uuid');

  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null); 
  const [attachments, setAttachments] = useState([]);
  const { receiver_id } = currentRoom;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const newAttachments = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setAttachments([...attachments, ...newAttachments]);
  };

  const removeAttachment = (index) => {
    const newAttachments = [...attachments];
    URL.revokeObjectURL(newAttachments[index].preview);
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  }; 

  const uploadAttachments = async (attachments) => {
    const CHUNK_SIZE = 3;
    const results = [];

    for (let i = 0; i < attachments.length; i += CHUNK_SIZE) {
      const chunk = attachments.slice(i, i + CHUNK_SIZE);
      const formData = new FormData();
      
      for (const attachment of chunk) {
        let processedFile = attachment.file;
        
        if (attachment.file.type.startsWith('image/')) {
          try {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            };
            processedFile = await imageCompression(attachment.file, options);
          } catch (error) {
            console.error('Error compressing image:', error);
            processedFile = attachment.file;
          }
        }
        
        formData.append('files', processedFile);
      }
      
      formData.append('spaceId', spaceID);
      formData.append('roomId', roomId);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}messages/attachments/upload`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) throw new Error('Upload failed');
        const data = await response.json();
        results.push(...data.attachments);
      } catch (error) {
        console.error('Error uploading chunk:', error);
        throw error;
      }
    }

    return results;
  };

  const sendMessage = async () => {
    if (socket && roomId) {

      const encryptedMessage = await encryptMessage(userID, spaceID, message);
      let attachmentResults = [];
      
      if (attachments.length > 0) {
        try {
          attachmentResults = await uploadAttachments(attachments);
        } catch (error) {
          console.error('Error uploading attachments:', error);
          return;
        }
      }

      const newMessage = {
        space: spaceID, 
        room: roomId, 
        type: roomType, 
        sender_id: userID, 
        receiver_id: receiver_id, 
        message: encryptedMessage,
        attachments: attachmentResults.map(result => ({
          key: result.key,
          type: result.type,
          name: result.originalName
        })),
        created_at: new Date().toISOString(),
        message_id: uuidv4(),
        parent_room_type: parentRoomType,
        parent_room_id: parentRoomId,
        parent_created_at: parentCreatedAt,
      };

      socket.emit("send_message", newMessage);

      // Thread logic
      if (roomType === 'thread') {
        const parentMessage = useSidebarStore.getState().messages[parentRoomId]?.find(msg => msg.message_id === roomId);
        const currentReplyCount = parentMessage?.reply_count || 0;
        const updatedParentMessage = {
          ...parentMessage,
          reply_count: currentReplyCount + 1,
          last_reply_at: new Date().toISOString()
        };
        useSidebarStore.getState().updateMessage(parentRoomId, updatedParentMessage);
        socket.emit("reply_added", updatedParentMessage);
      }

      // fix
      const decryptedMessage = {
        ...newMessage,
        message: await decryptMessage(userID, spaceID, encryptedMessage)
      };
      
      useSidebarStore.getState().addMessage(roomId, decryptedMessage); 

      setMessage("");
      setAttachments([]);   
      exitDMCreationFlow();
    }
  };

  const exitDMCreationFlow = () => {
    if (isDMCreationFlowActive) {
      useSidebarStore.getState().toggleDMCreationFlow();
    }
  }; 

  const resetTextareaHeight = () => {
    const textarea = document.getElementById('messageInput');
    if (textarea) {
      textarea.style.height = 'auto';
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (message.trim() || attachments.length > 0) {
        sendMessage();
        resetTextareaHeight();
      }
    }
  };

  const handleEmojiSelect = (emoji) => {
    setMessage(prevMessage => prevMessage + emoji);
  };

  return {
    message,
    setMessage,
    sendMessage,
    fileInputRef,
    handleFileSelect,
    removeAttachment,
    attachments,
    setAttachments,
    handleKeyPress,
    resetTextareaHeight,
    showEmojiPicker,
    setShowEmojiPicker,
    handleEmojiSelect,
  };
};

export default useTextBoxViewModel;