import React, { useMemo } from 'react';
import { FaCamera, FaSpinner, FaTimes } from 'react-icons/fa';
import styles from './ImageUpload.module.css';
import Avatar from 'react-avatar';
import { getAvatarColor } from '../../utils/imageUtils';

const ImageUpload = ({ image, name, isLoading, onImageClick, onRemoveImage, size = "100" }) => {
  const avatarColor = useMemo(() => {
    if (!name) return '#1abc9c';
    return getAvatarColor(name);
  }, [name?.trim().charAt(0)]);  

  return (
    <div className={styles.imageContainer} onClick={!image ? onImageClick : undefined}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <FaSpinner className={styles.spinner} />
        </div>
      ) : image ? (
        <div className={styles.imageWrapper}>
          <img src={image} alt="Upload" className={styles.uploadedImage} />
          <button 
            className={styles.removeButton} 
            onClick={(e) => {
              e.stopPropagation();
              onRemoveImage();
            }}
          >
            <FaTimes />
          </button>
        </div>
      ) : (
        <div className={styles.defaultImage}>
          {name ? (
            <Avatar 
              name={name} 
              size={size} 
              round={false}
              color={avatarColor}
              style={{ borderRadius: '10px' }}
            />
          ) : (
            <div className={styles.uploadPrompt}>
              <FaCamera className={styles.cameraIcon} />
              <span>Upload</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;