import React from 'react';

const PrivacyPolicyView = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p><em>Last Updated: 11-22-24</em></p>
      <p>Mention ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we handle your information in connection with our encrypted messaging service for consumers and businesses.</p>
      <p>By using Mention, you agree to the collection, use, and sharing of your information as described in this policy. If you do not agree, please refrain from using our Service.</p>

      <h2>1. Data We Do Not Collect</h2>
      <p>Your privacy is paramount. Mention uses end-to-end encryption to secure the content of your messages ("payload"). We will never train an AI model on your messages, and we will never sell your message data to third parties. This means:</p>
      <ul>
        <li><strong>No Payload Access:</strong> We do not have access to the content of your messages, files, or media sent or received within the Service.</li>
        <li><strong>No Tracking:</strong> We do not track your activity across the internet, and we do not sell or share your payload data with third parties.</li>
      </ul>

      <h2>2. Data We Collect</h2>
      <p>While we do not access your message content, we collect and process certain metadata to operate and improve the Service:</p>
      <ul>
        <li><strong>Account Information:</strong> Basic information during registration, such as username, email address, and password, to establish and maintain your account.</li>
        <li><strong>Message Metadata:</strong> Metadata, such as timestamps, sender and recipient identifiers, and message delivery status, may be collected to facilitate message delivery and troubleshoot technical issues.</li>
        <li><strong>Device and Usage Data:</strong> Device identifiers, IP addresses, and usage data, such as feature interactions, may be collected to improve Service performance and provide user support.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use collected information only as necessary to:</p>
      <ul>
        <li>Provide and maintain the Service.</li>
        <li>Improve and troubleshoot Service performance.</li>
        <li>Analyze usage patterns to improve our features, without compromising your privacy.</li>
        <li>Comply with legal obligations and secure our Service against abuse and fraud.</li>
      </ul>

      <h2>4. How We Share Your Information</h2>
      <p>We do not share your payload data with any third parties. In limited cases, we may share metadata:</p>
      <ul>
        <li><strong>Service Providers:</strong> Metadata may be shared with trusted third-party service providers for technical support, infrastructure management, and service improvement. These providers are bound by confidentiality agreements and cannot use your information outside of their Service role.</li>
        <li><strong>Legal Compliance:</strong> Metadata may be disclosed if required by law or to enforce our Terms of Service, protect user safety, and defend against fraud or security threats.</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We use encryption, access controls, and other security measures to protect your information. However, no system is entirely secure, and we cannot guarantee absolute security. We encourage you to secure your device and account information to prevent unauthorized access.</p>

      <h2>6. Data Retention</h2>
      <p>We retain account and metadata information only as long as necessary to provide the Service or comply with legal requirements. When no longer needed, we take steps to delete or anonymize your data.</p>

      <h2>7. Your Rights</h2>
      <p>Depending on your jurisdiction, you may have the right to access, correct, or delete your information. Contact us at <a href="mailto:team@mention.so">team@mention.so</a> if you wish to exercise these rights.</p>

      <h2>8. Changes to This Policy</h2>
      <p>We may update this Privacy Policy periodically. When changes are made, we will update the date at the top. Continued use of the Service after an update constitutes acceptance of the new Privacy Policy.</p>

      <h1>Terms of Service</h1>
      <p><em>Last Updated: 11-22-24</em></p>
      <p>Welcome to Mention ("we," "our," or "us"). These Terms of Service ("Terms") govern your use of Mention (the "Service"), an encrypted messaging service for consumers and businesses. By using our Service, you agree to these Terms. If you do not agree, please do not use our Service.</p>

      <h2>1. Privacy and Security</h2>
      <p>Your privacy is of utmost importance to us. All messages sent via Mention are end-to-end encrypted. This means the content ("payload") of your messages is only accessible to you and your chosen recipients. We do not and cannot read or access the payload of any messages.</p>
      <p>However, we may collect and process certain metadata to operate and improve the Service. Metadata may include, but is not limited to, message timestamps, sender and receiver identifiers (e.g., usernames), and technical data such as device information and usage logs. For more information, please review our Privacy Policy.</p>

      <h2>2. Your Responsibilities</h2>
      <p>By using our Service, you agree:</p>
      <ul>
        <li>Not to use the Service for illegal activities, spam, harassment, or abusive behavior.</li>
        <li>To respect the rights of other users and avoid sharing harmful or inappropriate content.</li>
        <li>To be responsible for the security of your account, including maintaining the confidentiality of any passwords or authentication methods.</li>
      </ul>

      <h2>3. Account Registration and Usage</h2>
      <p>When you register for an account, you must provide accurate and up-to-date information. You are responsible for any activity under your account. If you believe your account has been compromised, please notify us immediately.</p>

      <h2>4. Business Use of the Service</h2>
      <p>For business users, additional terms may apply as outlined in our Business Services Agreement. You are responsible for ensuring all business-related usage complies with applicable laws and regulations.</p>

      <h2>5. Intellectual Property</h2>
      <p>All content and features available on the Service, including but not limited to software, design, and trademarks, remain our property or the property of our licensors. You are granted a limited, non-exclusive license to use the Service as intended.</p>

      <h2>6. Termination</h2>
      <p>We reserve the right to suspend or terminate your access to the Service at any time for violating these Terms, without prior notice.</p>

      <h2>7. Limitation of Liability</h2>
      <p>To the extent permitted by law, we are not liable for any damages arising from your use of or inability to use the Service, including any data loss or security breaches resulting from unauthorized access or flaws in the software.</p>

      <h2>8. Changes to Terms</h2>
      <p>We may update these Terms periodically. When we make changes, we will update the date at the top of the Terms. Continued use of the Service following an update constitutes acceptance of the new Terms.</p>

      <p>For any questions or concerns regarding this Privacy Policy or Terms of Service, please contact us at <a href="mailto:team@mention.so">team@mention.so</a>.</p>
    </div>
  );
};

export { PrivacyPolicyView };
