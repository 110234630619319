const getFolderContents = async (spaceId, folderId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}folders/${spaceId}/${folderId}/contents`);
    if (!response.ok) {
      throw new Error("Failed to fetch folder contents");
    }
    
    return response.json();
  } catch (error) {
    console.error("Error fetching folder contents:", error);
    return [];
  }
};

const createChannel = async (folderId, channelData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}folders/${folderId}/create-channel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(channelData)
    });

    if (!response.ok) {
      throw new Error("Failed to add channel to folder");
    }
    
    return response.json();
  } catch (error) {
    console.error("Error adding channel to folder:", error); 
    return null;
  }
};

const deleteChannel = async (spaceId, folderId, channelId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}folders/${folderId}/delete-channel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ spaceId, channelId })
    });

    if (!response.ok) {
      throw new Error("Failed to remove channel from folder");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error removing channel from folder:", error);
    return null;
  }
};

export { getFolderContents, createChannel, deleteChannel };