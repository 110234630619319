import React from 'react';
import styles from './SidebarContextMenu.module.css';

const AddNewContextMenuView = ({ mouseX, mouseY, onClose, fadingOut, createRoom, createFolder }) => {
  return (
    <div 
      className={`${styles.sidebarContextMenu} ${fadingOut ? styles.fadeOut : ''}`}
      style={{ 
        top: mouseY,
        left: mouseX
      }}
      onClick={onClose}
    >
      <ul className={styles.addNewMenu}>
        <li onClick={() => {
            createRoom();
            onClose();
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.05em" height="1.05em" viewBox="0 0 36 36">
            <path fill="currentColor" d="M31.87 10h-5.55l1-4.83A1 1 0 0 0 26.35 4h-2a1 1 0 0 0-1 .78L22.33 10h-5.4l1-4.83A1 1 0 0 0 17 4h-2a1 1 0 0 0-1 .78L13 10H7a1 1 0 0 0-1 .8l-.41 2a1 1 0 0 0 1 1.2h5.55l-1.64 8h-6a1 1 0 0 0-1 .8l-.41 2a1 1 0 0 0 1 1.2h5.59l-1 4.83a1 1 0 0 0 1 1.17h2a1 1 0 0 0 .95-.78L13.67 26h5.4l-1 4.83A1 1 0 0 0 19 32h2a1 1 0 0 0 1-.78L23.05 26h6a1 1 0 0 0 1-.8l.4-2a1 1 0 0 0-1-1.2h-5.58l1.63-8h6a1 1 0 0 0 1-.8l.41-2a1 1 0 0 0-1.04-1.2m-12 12h-5.4l1.64-8h5.4Z" />
          </svg>
          Create New Channel
        </li>
        <li onClick={() => {
            createFolder();
            onClose();
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 18H4V8h16m0-2h-8l-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2"/>
          </svg>
          Create New Folder
        </li>
      </ul>
    </div>
  );
};

export default AddNewContextMenuView;