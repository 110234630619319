import imageCompression from 'browser-image-compression';
import randomColor from 'randomcolor';

// Consistent color generation function
export const getAvatarColor = (name) => {
  return randomColor({ 
    luminosity: 'bright',
    seed: name 
  });
};

export const createDefaultAvatar = (name, existingColor = null) => {
  const color = existingColor || getAvatarColor(name);
  const initial = name.charAt(0).toUpperCase();
  
  const svg = `
    <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="100" rx="10" fill="${color}"/>
      <text x="50" y="50" text-anchor="middle" dy="0.35em" fill="white" font-size="40" font-family="Arial">${initial}</text>
    </svg>
  `;
  
  return new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
};

export const compressImage = async (imageFile) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };
  try {
    return await imageCompression(imageFile, options);
  } catch (error) {
    console.error('Error compressing image:', error);
    return imageFile;
  }
};