import { getWorkspaceSharedKey } from './indexedDBService';

async function encryptMessage(userId, workspaceId, message) {
  const sharedKey = await getWorkspaceSharedKey(userId, workspaceId);

  if (!sharedKey) {
    throw new Error("Workspace shared key not found. Please generate new keys.");
  }

  // Generate random IV
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  // Encrypt message with shared key
  const encoder = new TextEncoder();
  const messageData = encoder.encode(message);
  const encryptedMessage = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    sharedKey,
    messageData
  );

  // Combine IV and encrypted message
  const combined = new Uint8Array(iv.length + encryptedMessage.byteLength);
  combined.set(iv, 0);
  combined.set(new Uint8Array(encryptedMessage), iv.length);

  return btoa(String.fromCharCode.apply(null, combined));
}

async function decryptMessage(userId, workspaceId, encryptedData) {
  if (!encryptedData) return '';
  
  const sharedKey = await getWorkspaceSharedKey(userId, workspaceId);
  
  if (!sharedKey) {
    throw new Error("Workspace shared key not found. Please generate new keys.");
  }

  try {
    // Decode the base64 string
    const combined = new Uint8Array(atob(encryptedData).split('').map(char => char.charCodeAt(0)));

    // Try hybrid decryption first
    try {
      // Extract IV (12 bytes)
      const iv = combined.slice(0, 12);
      const encryptedMessage = combined.slice(12);

      // Decrypt the message
      const decryptedData = await window.crypto.subtle.decrypt(
        { name: "AES-GCM", iv: iv },
        sharedKey,
        encryptedMessage
      );

      const decoder = new TextDecoder();
      return decoder.decode(decryptedData);
    } catch (hybridError) {
      // If hybrid decryption fails, try legacy RSA-OAEP decryption
      const decryptedData = await window.crypto.subtle.decrypt(
        { name: "RSA-OAEP" },
        sharedKey,
        combined
      );

      const decoder = new TextDecoder();
      return decoder.decode(decryptedData);
    }
  } catch (error) {
    console.error("Decryption failed:", error);
    throw error;
  }
}

export { encryptMessage, decryptMessage };