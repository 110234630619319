import React from 'react';
import styles from './Message.module.css';

const ReplyInfo = ({ replyCount, lastReplyAt, onClick }) => {
  if (!replyCount || replyCount === 0) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { 
      month: 'short', 
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div className={styles.replyInfo} onClick={handleClick}>
      <div className={styles.replyInfoContent}>
        <span className={styles.replyCount}>{replyCount} repl{replyCount === 1 ? 'y' : 'ies'}</span>
        {lastReplyAt && (
          <span className={styles.lastReplyTime}>Last reply {formatDate(lastReplyAt)}</span>
        )}
      </div>
      <button className={styles.sendButton} onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="0.7em" height="0.7em" viewBox="0 0 20 20">
          <path fill="currentColor" d="m0 0l20 10L0 20zm0 8v4l10-2z" />
        </svg>
      </button>
    </div>
  );
};

export default ReplyInfo;