import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../store/authStore';
import styles from '../css/SpaceDetailsStep.module.css';
import { createSpace, updateRole, updateOnboardingStatus } from '../services/onboardingService';
import { generateUniqueCode } from '../../../utils/uniqueIDGenerator';
import { updateUser } from '../services/onboardingService';
import ImageUpload from './ImageUploads/ImageUpload';
import { compressImage, createDefaultAvatar, getAvatarColor } from '../utils/imageUtils';

import { generateAndRegisterUserKeys } from '../../../services/keyGenerationService';
import { registerWorkspaceSharedKey } from '../../../services/workspaceKeyService';

import KeyGenerationLoadingView from '../../../features/encryption/KeyGenerationLoadingView';

const SpaceDetailsStep = () => {
  const [spaceName, setSpaceName] = useState('');
  const [spaceImage, setSpaceImage] = useState(null);
  const [spaceDescription, setSpaceDescription] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingKeys, setIsGeneratingKeys] = useState(false);
  
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  
  const { userEmail, userID, setOnboardingStatus, establishUserSpace } = useAuthStore();

  useEffect(() => {
    setIsFormValid(spaceName.trim().length > 0);
  }, [spaceName]);

  const handleRemoveImage = () => {
    setSpaceImage(null);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  }; 

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSpaceImage(reader.result);
        setIsLoading(false);
      };
      reader.readAsDataURL(compressedFile);
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid) return;
    setIsGeneratingKeys(true);

    try {
      let imageToSend = spaceImage;
      if (!spaceImage && spaceName) {
        const previewColor = getAvatarColor(spaceName);
        imageToSend = createDefaultAvatar(spaceName, previewColor);
      }

      const spaceID = generateUniqueCode();
      const userRole = 'admin';
      const status = 'create/profile';

      await generateAndRegisterUserKeys(userID, spaceID, spaceName);
      await registerWorkspaceSharedKey(userID, spaceID);
      await createSpace(userID, spaceID, spaceName, imageToSend, spaceDescription, false);
      await updateRole(userID, spaceID, userRole);
      await updateUser(userEmail, spaceID);
      await updateOnboardingStatus(userID, spaceID, status);

      establishUserSpace(spaceID, userRole);
      setOnboardingStatus(status);
      navigate('/create/profile', { replace: true });

    } catch (error) {
      console.error('Error creating space:', error);
      setIsGeneratingKeys(false);
    }
  };

  if (isGeneratingKeys) {
    return <KeyGenerationLoadingView />;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Create a Space</h2>
      
      <div className={styles.spaceInfo}>
        <p className={styles.label}>Space Image</p>
        <ImageUpload
          image={spaceImage}
          name={spaceName}
          isLoading={isLoading}
          onImageClick={handleImageClick}
          onRemoveImage={handleRemoveImage}
          size="100"
        />

        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />

        <p className={styles.label}>Space Name</p>
        <input
          type="text"
          className={styles.spaceNameInput}
          value={spaceName}
          placeholder="Enter your space name"
          onChange={(e) => setSpaceName(e.target.value)}
          maxLength={25}
        />

        <p className={styles.label}>Space Description</p>
        <textarea
          className={styles.spaceDescriptionInput}
          placeholder="Enter your space description"
          value={spaceDescription}
          onChange={(e) => setSpaceDescription(e.target.value)}
          maxLength={200}
        />
      </div>
      
      <button
        className={styles.secondary_button}
        onClick={handleSubmit}
        disabled={!isFormValid || isGeneratingKeys}>
        Next
      </button>
    </div>
  );
};

export { SpaceDetailsStep };