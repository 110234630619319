import React from 'react';
import useTypingIndicatorViewModel from './TypingIndicatorViewModel';
import styles from './TypingIndicator.module.css';

const TypingIndicatorView = () => {
  const { typingIndicator } = useTypingIndicatorViewModel();

  if (!typingIndicator.isTyping) {
    return null;
  }

  return (
    <div className={styles.typingIndicator}>
      {typingIndicator.user} is typing...
    </div>
  );
};

export default TypingIndicatorView;
