import React from 'react';
import styles from './WorkspaceSettingsView.module.css';

const WorkspaceSettingsView = () => {
  return (
    <div className={styles.container}>
      <h1>Workspace Settings</h1>
      
      <div className={styles.section}>
        <div className={styles.imageSection}>
          <div className={styles.addPhoto}>
            <div className={styles.placeholder}>
              <i className="fa-solid fa-image"></i>
            </div>
            <span>Add Photo</span>
          </div>
          
          <div className={styles.changeCover}>
            <div className={styles.coverPreview}>_sandbox</div>
            <span>Change Cover</span>
          </div>
        </div>

        <div className={styles.formSection}>
          <div className={styles.inputGroup}>
            <label>Workspace Name</label>
            <input type="text" maxLength={50} value="Sandbox" className={styles.input} />
          </div>

          <div className={styles.inputGroup}>
            <label>Workspace Bio</label>
            <textarea 
              className={styles.textarea}
              maxLength={500}
              value="Sandbox is a Utah-based startup incubator."
            />
          </div>
        </div>
      </div>

      <div className={styles.customizeSection}>
        <h2>Customize Workspace</h2>
        
        <div className={styles.setting}>
          <div>
            <h3>Appearance</h3>
            <p>Change the theme of your workspace.</p>
          </div>
          <select className={styles.select}>
            <option>Dark</option>
          </select>
        </div>

        <div className={styles.setting}>
          <div>
            <h3>Default Timezone</h3>
            <p>Select the timezone of your HQ. Can be overridden by individuals.</p>
          </div>
          <select className={styles.select}>
            <option>GMT-6</option>
          </select>
        </div>

        <div className={styles.setting}>
          <div>
            <h3>Storage Limit</h3>
            <p>10GB limit on the free tier plan. Upgrade for more.</p>
          </div>
          <select className={styles.select}>
            <option>10GB</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettingsView;