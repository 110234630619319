import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { logout } from '../features/authentication/services/AuthService';

/**
 * Custom hook to handle the logout process.
 *
 * - Calls the server-side logout endpoint to clear the user's session.
 * - Updates the client-side authentication state via authStore.
 * - Navigates the user to the login page upon successful logout.
 *
 * @returns {Function} The function to trigger the logout process.
 */
const useLogout = () => {
  const navigate = useNavigate();
  const terminateUserSession = useAuthStore((state) => state.terminateUserSession);
 
  const handleLogout = async () => {
    try {
      const success = await logout();
      if (success) {
        terminateUserSession();
        navigate('/', { replace: true });
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return handleLogout;
};

export default useLogout;
