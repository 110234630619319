import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { updateLastActiveSpace } from '../features/onboarding/services/onboardingService';

// ADD A CHECK TO SEE IF THE USER IS A MEMBER OF THE SPACE
export const SpaceRouteWrapper = ({ children }) => {
  const { spaceID } = useParams();
  const userEmail = useAuthStore(state => state.userEmail);

  useEffect(() => {
    if (spaceID && userEmail) {
      updateLastActiveSpace(userEmail, spaceID);
    }
  }, [spaceID, userEmail]);

  return children;
};
