import React from 'react';
import KeyManagementSection from './keyManagement/KeyManagementView';
import WorkspaceSettingsView from './spaceSettings/WorkspaceSettingsView';
import BillingView from '../../billing/components/BillingView';
import MemberPortalView from './memberPortal/MemberPortalView';
import NotificationsView from './notifications/NotificationsView';
import { AccountView } from './accountSettings/AccountView';

const SettingsContent = ({ section, spaceDetails }) => {
  const renderContent = () => {
    switch (section) {
      case 'key-management':
        return <KeyManagementSection />;
      case 'workspace-settings':
        return <WorkspaceSettingsView />;
      case 'billing':
        return <BillingView />;
      case 'account':
        return <AccountView />;
      case 'member-portal':
        return <MemberPortalView spaceDetails={spaceDetails} />;
      case 'notifications':
        return <NotificationsView />;
      default:
        return (
          <p>coming soon...</p>
        );
    }
  };

  return renderContent();
};

export default SettingsContent;