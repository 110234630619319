import React, { useState } from 'react';
import styles from './NotificationsView.module.css';
import useSidebarStore from '../../../../store/sidebarStore';

const NotificationsView = () => {
  const [activeTab, setActiveTab] = useState('Channels');
  const [globalSoundEnabled, setGlobalSoundEnabled] = useState(false);
  
  const { channelDetails, folderContents, dmConversations, groupChats } = useSidebarStore();

  const allChannels = Object.values(folderContents).flat();
  
  const tabs = [
    { name: 'Channels', count: allChannels.length },
    { name: 'Group Chats', count: groupChats.length },
    { name: 'DMs', count: dmConversations.length },
    { name: 'All', count: allChannels.length + groupChats.length + dmConversations.length }
  ];

  const renderRoomList = () => {
    let rooms = [];
    
    switch(activeTab) {
      case 'Channels':
        rooms = allChannels.map(channel => ({
          id: channel.channel_id,
          name: channelDetails[channel.channel_id]?.channel_name || channel.channel_id,
          type: 'channel'
        }));
        break;
      case 'Group Chats':
        rooms = groupChats.map(group => ({
          id: group.group_id,
          name: group.group_members?.join(', ') || 'Unnamed Group',
          type: 'group'
        }));
        break;
      case 'DMs':
        rooms = dmConversations.map(dm => ({
          id: dm.dm_id,
          name: dm.other_user_id,
          type: 'dm'
        }));
        break;
      case 'All':
        rooms = [
          ...allChannels.map(channel => ({
            id: channel.channel_id,
            name: channelDetails[channel.channel_id]?.channel_name || channel.channel_id,
            type: 'channel'
          })),
          ...groupChats.map(group => ({
            id: group.group_id,
            name: group.group_members?.join(', ') || 'Unnamed Group',
            type: 'group'
          })),
          ...dmConversations.map(dm => ({
            id: dm.dm_id,
            name: dm.other_user_id,
            type: 'dm'
          }))
        ];
        break;
      default:
        rooms = [];
    }

    return rooms;
  };

  return (
    <div className={styles.container}>
      <h1>Notifications</h1>
      
      <div className={styles.globalSettings}>
        <div className={styles.settingRow}>
          <div>
            <h2>Turn on all sound notifications</h2>
            <p>This will ping you across all channels, group chats, and dms.</p>
          </div>
          <button 
            className={`${styles.button} ${globalSoundEnabled ? styles.enabled : ''}`}
            onClick={() => setGlobalSoundEnabled(!globalSoundEnabled)}
          >
            {globalSoundEnabled ? 'Enabled' : 'Enable'}
          </button>
        </div>
      </div>

      <div className={styles.managementSection}>
        <h2>Management Dashboard</h2>
        
        <div className={styles.tabs}>
          {tabs.map(tab => (
            <button
              key={tab.name}
              className={`${styles.tab} ${activeTab === tab.name ? styles.activeTab : ''}`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name} ({tab.count})
            </button>
          ))}
        </div>

        <div className={styles.roomList}>
          <div className={styles.roomHeader}>
            <span>Room Name</span>
            <span>Sound Alerts</span>
            <span>Notifications</span>
          </div>
          
          {renderRoomList().map(room => (
            <div key={room.id} className={styles.roomRow}>
              <div className={styles.roomName}>
                <span className={styles.hashIcon}>#</span> {room.name}
              </div>
              <button className={`${styles.button} ${styles.soundButton}`}></button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationsView;