import React, { useState, useEffect } from 'react';
import styles from './SettingsModal.module.css';
import SettingsContent from './SettingsContent';
import useUserDetailsStore from '../../../store/userDetailsStore';
import useAuthStore from '../../../store/authStore';
import useLogout from '../../../hooks/useLogout'; 
import useSettingsStore from '../../../store/settingsStore';

const SettingsModal = ({ onClose }) => {
  const selectedSection = useSettingsStore(state => state.selectedSection);
  const setSelectedSection = useSettingsStore(state => state.setSelectedSection);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const { spaceID } = useAuthStore();
  const userDetails = useUserDetailsStore(state => state.userDetails);
  const currentUser = userDetails.get(useAuthStore.getState().userID);
  
  const logout = useLogout();

  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}space/${spaceID}/get-space-details`
        );
        const details = await response.json();
        setSpaceDetails(details);
      } catch (error) {
        console.error('Error fetching space details:', error);
      }
    };

    if (spaceID) {
      fetchSpaceDetails();
    }
  }, [spaceID]);

  const onLogoutClick = async () => {
    await logout();
  };

  const handleClose = () => {
    setSelectedSection('account');
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const menuItems = {
    workspace: [
      { 
        id: 'upgrade', 
        label: 'Upgrade plan', 
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 32 32">
            <path fill="currentColor" d="M21 24H11a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2m0 4H11v-2h10zm7.707-13.707l-12-12a1 1 0 0 0-1.414 0l-12 12A1 1 0 0 0 4 16h5v4a2 2 0 0 0 2 2h10a2.003 2.003 0 0 0 2-2v-4h5a1 1 0 0 0 .707-1.707M21 14v6H11v-6H6.414L16 4.414L25.586 14z" />
          </svg>
        )
      },
      { 
        id: 'key-management', 
        label: 'Key management', 
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M11.9 11H22v4.004c0 .546-.45.996-1 .996s-1-.45-1-.996V13h-2v2c0 .55-.45 1-1 1s-1-.45-1-1v-2h-4.1A5.002 5.002 0 0 1 2 12a5 5 0 0 1 9.9-1M7 15a3 3 0 1 0 0-6a3 3 0 0 0 0 6" />
          </svg>
        )
      },
      { 
        id: 'member-portal', 
        label: 'Member portal', 
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5M4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12m0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7m7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44M15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35" />
          </svg>
        )
      },
      { 
        id: 'workspace-settings', 
        label: 'Workspace settings', 
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29a.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19a1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22a.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5a1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29a.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19a1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22a.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5a1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22m-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14a2.36 2.36 0 0 1 2.59.47l.54.54l.88-.88l-.54-.55a2.34 2.34 0 0 1-.48-2.56a2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46a2.36 2.36 0 0 1 .47-2.59l.54-.54l-.88-.88l-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14a2.36 2.36 0 0 1-2.59-.47l-.54-.54l-.88.88l.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46a2.36 2.36 0 0 1-.47 2.59l-.54.54l.88.88l.55-.54a2.39 2.39 0 0 1 4 1.67Z" />
            <path fill="currentColor" d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5m0-5a1.5 1.5 0 1 0 1.5 1.5a1.5 1.5 0 0 0-1.5-1.5" />
          </svg>
        )
      },
      { 
        id: 'billing', 
        label: 'Billing & support', 
        icon: 'fa-solid fa-credit-card' 
      },
    ],
    account: [
      { 
        id: 'account', 
        label: 'My account', 
        icon: 'fa-solid fa-address-card' 
      },
      { 
        id: 'notifications', 
        label: 'Notifications', 
        icon: 'fa-solid fa-bell' 
      },
    ],
    bottom: [
      { 
        id: 'whats-new', 
        label: "What's New?", 
        icon: '✨',
        className: styles.whatsNewItem
      },
      { 
        id: 'logout', 
        label: 'Logout', 
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
              <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2" />
              <path d="M9 12h12l-3-3m0 6l3-3" />
            </g>
          </svg>
        ),
        className: styles.logoutItem,
        onClick: onLogoutClick
      }
    ]
  };

  return (
    <div className={styles.modalContainer} onClick={handleOverlayClick}>
      <div className={styles.modalContent}>
        <div className={styles.sidebar}>
          <div className={styles.sectionHeader}>Workspace</div>
          {spaceDetails ? (
            <div className={styles.profilePreview}>
              <img 
                src={spaceDetails.space_image_url} 
                alt="Workspace" 
                className={styles.profileImage} 
              />
              <div className={styles.profileInfo}>
                <div className={styles.profileName}>{spaceDetails.space_name}</div>
                <div className={styles.spaceDescription} title={spaceDetails.space_description}>
                  {spaceDetails.space_description.length > 30 
                    ? `${spaceDetails.space_description.substring(0, 30)}...` 
                    : spaceDetails.space_description}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.profilePreview}>
              <div className={styles.profileImage} />
              <div className={styles.profileInfo}>
                <div className={styles.profileName}>Loading...</div>
                <div className={styles.spaceDescription}>Loading...</div>
              </div>
            </div>
          )}

          {menuItems.workspace.map(item => (
            <div
              key={item.id}
              data-id={item.id}
              className={`${styles.menuItem} ${selectedSection === item.id ? styles.active : ''}`}
              onClick={() => {
                if (item.id === 'upgrade') {
                  setSelectedSection('billing');
                } else {
                  setSelectedSection(item.id);
                }
              }}
            >
              {typeof item.icon === 'string' ? (
                <i className={item.icon}></i>
              ) : (
                item.icon
              )}
              {item.label}
            </div>
          ))}

          <div className={styles.gap}></div>

          <div className={styles.sectionHeader}>Account</div>
          <div className={styles.profilePreview}>
            <img 
              src={currentUser?.userSpaceDetails?.user_image_url} 
              alt="Profile" 
              className={styles.profileImage} 
            />
            <div className={styles.profileInfo}>
              <div className={styles.profileName}>{currentUser?.userSpaceDetails?.user_display_name}</div>
              <div className={styles.profileEmail}>thearvih@gmail.com</div>
            </div>
          </div>
          {menuItems.account.map(item => (
            <div
              key={item.id}
              className={`${styles.menuItem} ${selectedSection === item.id ? styles.active : ''}`}
              onClick={() => setSelectedSection(item.id)}
            >
              <i className={item.icon}></i>
              {item.label}
            </div>
          ))}

          <div className={styles.big_gap}></div>

          {menuItems.bottom.map(item => (
            <div
              key={item.id}
              className={`${styles.menuItem} ${item.className || ''}`}
              onClick={item.onClick || (() => setSelectedSection(item.id))}
            >
              {typeof item.icon === 'string' ? (
                <span className={styles.emoji}>{item.icon}</span>
              ) : (
                item.icon
              )}
              {item.label}
            </div>
          ))}
        </div>
        
        <div className={styles.content}>
          {/* Replace with a space store in the future */}
          <SettingsContent section={selectedSection} spaceDetails={spaceDetails} />
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;