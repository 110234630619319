import imageCompression from 'browser-image-compression';

const updateUserProfile = async (userID, spaceID, updates) => {
  try {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('spaceID', spaceID);

    // Only append changed values
    if (updates.displayName) {
      formData.append('displayName', updates.displayName);
    }
    if (updates.bio) {
      formData.append('bio', updates.bio);
    }
    if (updates.image) {
      let imageBlob;
      if (updates.image instanceof Blob || updates.image instanceof File) {
        imageBlob = updates.image;
      } else if (typeof updates.image === 'string' && updates.image.startsWith('data:')) {
        const response = await fetch(updates.image);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(imageBlob, options);
      formData.append('userImage', compressedFile, 'userImage.jpg');
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/update-profile`,
      {
        method: "PUT",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update profile');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

export { updateUserProfile };
