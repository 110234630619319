const fetchLinkPreview = async (url) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}link-preview?url=${encodeURIComponent(url)}`);

    if (!response.ok) {
      return null;
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
};

export { fetchLinkPreview };