import { create } from 'zustand';

const useUIStore = create((set, get) => ({
  openModal: (userID) => set({ isModalOpen: true, modalUserId: userID }),
  closeModal: () => set({ isModalOpen: false, modalUserId: null }),

  activeQuickViewUserId: null,
  quickViewPosition: { top: 0, left: 0 },
  isModalOpen: false,
  modalUserId: null,
  isHoveringTrigger: false,
  isHoveringMenu: false,
  closeTimeout: null,

  setActiveQuickView: (userID, position) => {
    clearTimeout(get().closeTimeout);
    set({ 
      activeQuickViewUserId: userID, 
      quickViewPosition: position 
    });
  },
  
  closeQuickView: () => {
    const closeTimeout = setTimeout(() => {
      if (!get().isHoveringTrigger && !get().isHoveringMenu) {
        set({ activeQuickViewUserId: null });
      }
    }, 250);
    set({ closeTimeout });
  },
  
  setHoveringTrigger: (isHovering) => {
    clearTimeout(get().closeTimeout);
    set({ isHoveringTrigger: isHovering });
    if (!isHovering) {
      get().closeQuickView();
    }
  },
  
  setHoveringMenu: (isHovering) => {
    clearTimeout(get().closeTimeout);
    set({ isHoveringMenu: isHovering });
    if (!isHovering) {
      get().closeQuickView();
    }
  },
}));

export default useUIStore;