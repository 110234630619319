const getChannelDetails = async (spaceId, channelId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}channels/${spaceId}/${channelId}/details`);

    if (!response.ok) {
      throw new Error("Failed to fetch channel details");
    }
    
    return response.json();
  } catch (error) {
    console.error("Error fetching channel details:", error);
    return null;
  }
};

const getSpaceChannels = async (space_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}get-space-channels/${space_id}`);

    if (!response.ok) {
      throw new Error("Failed to fetch space channels");
    }

    const channels = await response.json();
    return channels.map(channel => ({ ...channel, type: 'channel' }));
  } catch (error) {
    console.error("Error fetching space channels:", error);
    return null;
  }
};

const getChannelMessages = async (space_id, channel_id, before, limit) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}channels/${space_id}/${channel_id}/messages?before=${before}&limit=${limit}`, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error("Failed to get channel messages");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching channel messages:", error);
    return null;
  }
};

const deleteChannelMessage = async (space_id, channel_id, message_id, created_at) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}channels/${space_id}/${channel_id}/messages/${message_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ created_at })
    });

    if (!response.ok) {
      throw new Error("Failed to delete channel message");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error deleting channel message:", error);
    return null;
  }
};

export {
  getChannelDetails,
  getChannelMessages, 
  deleteChannelMessage,
  getSpaceChannels,
};
