/**
 * Generates a unique code consisting of upper and lower case letters and numbers.
 *
 * @param {number} [length=8] - The length of the generated code. Default is 8.
 * @returns {string} - The generated unique code.
 */
const generateUniqueCode = (length = 8) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export { generateUniqueCode };