import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LandingPage.module.css";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/login");
  };

  return (
    <div className={styles.landingPage}>
      <nav className={styles.navbar}>
        <div className={styles.logo}>
          <img src="/logo.png" alt="Mention" />
          <h1>Mention</h1>
        </div>
        <div className={styles.navLinks}>
          <a href="/about">About</a>
          <a href="/features">Features</a>
          <a href="/pricing">Pricing</a>
          <a href="/changelog">Changelog</a>
          <a href="/blog">Blog</a>
          <a href="/contact">Contact</a>
        </div>
        <button onClick={handleGetStarted} className={styles.getStartedButton}>
          Get started
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </nav>
      <main className={styles.mainContent}>
        <div className={styles.tag}>
          <span className={styles.tagIcon}>⚡</span>
          Zero Trust Security
        </div>

        <section className={styles.hero}>
          <h1>End-to-End Encrypted Messaging</h1>
          {/* <h2>Channels, threads, files, and more</h2> */}
          <h2>Channel-Based, Multi-Threaded Chat</h2>
          <p>
            Your data isn’t our training set. Mention provides encrypted team
            communication with all the features you need, without sacrificing
            privacy.
          </p>

          <div className={styles.heroButtons}>
            <button onClick={handleGetStarted} className={styles.tryButton}>
              Try for free
              <i className="fa-solid fa-arrow-right"></i>
            </button>
            <button className={styles.watchButton}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10 15.577L15.577 12L10 8.423zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
                />
              </svg>
              Watch video
            </button>
          </div>
        </section>

        <section className={styles.workspace}>
          <div className={styles.workspaceWindow}>
            <div className={styles.windowHeader}>
              <div className={styles.windowDots}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className={styles.workspaceContent}>
              <div className={styles.sidebar}>
                <div className={styles.workspaceNav}>
                  <h3>MentionHQ</h3>
                  <div className={styles.channels}>
                    <div className={styles.channelGroup}>
                      <ul>
                        <li># general</li>
                        <li># announcements</li>
                        <li># angels</li>
                        <li># podcasts</li>
                      </ul>
                    </div>
                    <div className={styles.channelGroup}>
                      <h4>Design</h4>
                      <ul>
                        <li className={styles.designChannel}>
                          # design-workshops
                        </li>
                        <li># UI/UX feedback</li>
                      </ul>
                    </div>
                    <div className={styles.channelGroup}>
                      <h4>Engineering</h4>
                      <ul>
                        <li># bug-reports</li>
                        <li># sb04-byu</li>
                        <li># updates</li>
                        <li># testing</li>
                        <li># QA-tickets</li>
                      </ul>
                    </div>
                    <div className={styles.channelGroup}>
                      <h4>Community</h4>
                      <ul>
                        <li># announcements</li>
                        <li># testing</li>
                        <li># random</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.chatArea}>
                <div className={styles.channelHeader}>
                  <div className={styles.channelInfo}>
                    <h2># design-workshops</h2>
                    <span>Collaborate on design projects and workshops</span>
                  </div>
                </div>

                <div className={styles.messageArea}>
                  <div className={styles.messageThread}>
                    <div className={styles.message}>
                      <img
                        src="/api/placeholder/40/40"
                        alt="User avatar"
                        className={styles.avatar}
                      />
                      <div className={styles.messageContent}>
                        <div className={styles.messageHeader}>
                          <span className={styles.username}>Peter Hu</span>
                          <span className={styles.timestamp}>12:14 AM</span>
                        </div>
                        <p className={styles.messageText1}>
                          Here's a great resource for collaborative design:
                        </p>
                        <div className={styles.linkPreview}>
                          <div className={styles.previewContent}>
                            <h4>🎨 Figma: The Collaborative Interface Design Tool</h4>
                            <p>
                              Design, prototype, and gather feedback all in one
                              place
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.message}>
                      <img
                        src="/api/placeholder/40/40"
                        alt="User avatar"
                        className={styles.avatar}
                      />
                      <div className={styles.messageContent}>
                        <div className={styles.messageHeader}>
                          <span className={styles.username}>Sarah Chen</span>
                          <span className={styles.timestamp}>12:16 AM</span>
                        </div>
                        <p className={styles.messageText}>
                          Thanks for sharing! I've been using it for our latest
                          project and the real-time collaboration features are
                          amazing.
                        </p>
                      </div>
                    </div>

                    <div className={styles.message}>
                      <img
                        src="/api/placeholder/40/40"
                        alt="User avatar"
                        className={styles.avatar}
                      />
                      <div className={styles.messageContent}>
                        <div className={styles.messageHeader}>
                          <span className={styles.username}>Alex Morgan</span>
                          <span className={styles.timestamp}>12:18 AM</span>
                        </div>
                        <p className={styles.messageText}>
                          Could we schedule a workshop to go through some
                          advanced Figma techniques? I'd love to learn more
                          about auto-layout and components.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.textbox_container}>
                  <div className={styles.messageInputContainer}>
                    <textarea
                      id="messageInput"
                      placeholder={"Message #podcasts / content"}
                      className={styles.messageInputTextInput}
                      rows={1}
                    />

                    <div className={styles.messageInputToolbar}>
                      <div className={styles.toolbarLeft}>
                        <button
                          className={`${styles.messageInputToolbarButton} ${styles.formatButton}`}
                        >
                          <p>Aa</p>
                        </button>

                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.3em"
                            height="1.3em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M21.796 9.982C20.849 5.357 16.729 2 12 2C6.486 2 2 6.486 2 12c0 4.729 3.357 8.849 7.982 9.796a.99.99 0 0 0 .908-.272l10.633-10.633c.238-.238.34-.578.273-.909M11 18a7.93 7.93 0 0 1 1.365-4.471a8 8 0 0 1 .978-1.186a8 8 0 0 1 1.184-.977a8 8 0 0 1 1.36-.738c.481-.203.986-.36 1.501-.466a8 8 0 0 1 2.17-.134l-8.529 8.529c-.013-.185-.029-.37-.029-.557m-7-6c0-4.411 3.589-8 8-8c2.909 0 5.528 1.589 6.929 4.005a10 10 0 0 0-1.943.198c-.643.132-1.274.328-1.879.583a10 10 0 0 0-1.699.923c-.533.361-1.03.771-1.479 1.22s-.858.945-1.221 1.48c-.359.533-.67 1.104-.922 1.698A10 10 0 0 0 9 18c0 .491.048.979.119 1.461C6.089 18.288 4 15.336 4 12"
                            />
                          </svg>
                        </button>

                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.25em"
                            height="1.25em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"
                            />
                          </svg>
                        </button>

                        <div className={styles.verticalDivider}></div>
                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707l1.414-1.414l-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.01 5.01 0 0 0 0 7.071a4.98 4.98 0 0 0 3.535 1.462A4.98 4.98 0 0 0 12 19.071l.707-.707l-1.414-1.414l-.707.707a3.007 3.007 0 0 1-4.243 0a3.005 3.005 0 0 1 0-4.243z"
                            />
                            <path
                              fill="currentColor"
                              d="m12 4.929l-.707.707l1.414 1.414l.707-.707a3.007 3.007 0 0 1 4.243 0a3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414l.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.01 5.01 0 0 0 0-7.071a5.006 5.006 0 0 0-7.071 0"
                            />
                          </svg>
                        </button>

                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.3em"
                            height="1.3em"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                              <path
                                fill="currentColor"
                                d="M4 12a8 8 0 0 1 8-8c5.367 0 8.445 4.445 8.006 8.39c-.12 1.086-.438 1.723-.72 2.095s-.571.54-.733.62c-.753.377-1.133.212-1.283.093c-.19-.15-.372-.503-.284-1.034l.006-.032l.503-5.032a1 1 0 0 0-1.867-.59A5.03 5.03 0 0 0 12.03 7C9.279 7 7 9.229 7 12c0 2.774 2.288 5 5.038 5c1.212 0 2.35-.436 3.237-1.176c.175.36.425.682.753.942c.917.726 2.172.752 3.42.128c.337-.168.91-.51 1.434-1.203s.956-1.682 1.112-3.08C22.556 7.554 18.633 2 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10a9.96 9.96 0 0 0 4.445-1.04a1 1 0 0 0-.89-1.791A8 8 0 0 1 4 12m5 0c0-1.647 1.364-3 3.03-3c1.92 0 3.364 1.767 2.974 3.62c-.291 1.378-1.539 2.38-2.966 2.38C10.368 15 9 13.645 9 12"
                              />
                            </g>
                          </svg>
                        </button>

                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.3em"
                            height="1.3em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4-8c.55 0 1-.45 1-1s-.45-1-1-1s-1 .45-1 1s.45 1 1 1m-8 0c.55 0 1-.45 1-1s-.45-1-1-1s-1 .45-1 1s.45 1 1 1m4 5.5c2.07 0 3.75-1.45 4.2-3.5H7.8c.45 2.05 2.13 3.5 4.2 3.5"
                            />
                          </svg>
                        </button>

                        <div className={styles.verticalDivider}></div>
                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.3em"
                            height="1.3em"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="currentColor"
                              fillRule="evenodd"
                              d="M10.218 3.216a.75.75 0 0 0-1.436-.431l-3 10a.75.75 0 0 0 1.436.43zM4.53 4.97a.75.75 0 0 1 0 1.06L2.56 8l1.97 1.97a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 0m6.94 6.06a.75.75 0 0 1 0-1.06L13.44 8l-1.97-1.97a.75.75 0 0 1 1.06-1.06l2.5 2.5a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06 0"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className={styles.toolbarRight}>
                        <button className={styles.messageInputToolbarButton}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                              <path
                                fill="currentColor"
                                d="M17.991 6.01L5.399 10.563l4.195 2.428l3.699-3.7a1 1 0 0 1 1.414 1.415l-3.7 3.7l2.43 4.194L17.99 6.01Zm.323-2.244c1.195-.433 2.353.725 1.92 1.92l-5.282 14.605c-.434 1.198-2.07 1.344-2.709.241l-3.217-5.558l-5.558-3.217c-1.103-.639-.957-2.275.241-2.709z"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.threadSidebar}>
                <div className={styles.threadHeader}>
                  <h3>Thread</h3>
                  <button className={styles.closeThread}>×</button>
                </div>
                <div className={styles.threadMessages}>
                  {/* Thread messages would go here */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export { LandingPage };
