import React, { useCallback, useState } from 'react';
import styles from './UserDetailsModal.module.css';
import useUserDetailsStore from '../../store/userDetailsStore';
import useAuthStore from '../../store/authStore';
import EditProfileModal from './EditProfileModal';


const UserDetailsModal = ({ userID, onClose, fromChat = false }) => {
  const userDetails = useUserDetailsStore(state => state.userDetails.get(userID));
  const currentUserID = useAuthStore(state => state.userID);
  const getUserStatus = useUserDetailsStore(state => state.getUserStatus);
  const isCurrentUser = currentUserID === userID;
  const userStatus = getUserStatus(userID);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  if (!userDetails) return null;

  const { userSpaceDetails } = userDetails;

  return (
    <>
      {showEditModal && (
        <EditProfileModal 
          userDetails={userDetails} 
          onClose={() => setShowEditModal(false)} 
        />
      )}
      
      <div className={`${styles.modalOverlay} ${fromChat ? styles.fromChat : ''}`} onClick={handleOverlayClick}>
        <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
          <div className={styles.header}>
            <img src={userSpaceDetails.user_image_url || 'default-avatar.png'} alt={`${userSpaceDetails.user_display_name}'s avatar`}className={styles.avatar} />
            {isCurrentUser && (
              <button 
                className={styles.editButton}
                onClick={() => setShowEditModal(true)}
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
            )}
          </div>
          <div className={styles.userDetailsContainer}>
            <h2>{userSpaceDetails.user_display_name}</h2>

            <p className={styles.bio}>{userSpaceDetails.user_bio || 'No bio available'}</p>

            <div className={styles.statusIndicator}>
              <span className={`${styles.statusDot} ${styles[userStatus]}`}></span>
              <span className={styles.statusText}>{userStatus}</span>
            </div>

            <div className={styles.divider}></div>

            <button className={styles.directMessageButton}>Direct Message</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsModal;