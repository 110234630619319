import React, { useState, useEffect } from 'react';
import styles from './CreateRoomModal.module.css';
import useCreateRoomModalViewModel from './CreateRoomModalViewModel';

const CreateRoomModalView = ({ isVisible, folderId, onClose }) => {
  const {
    roomName,
    setRoomName,
    setDescription,
    handleCreateRoom,
    setFolderId
  } = useCreateRoomModalViewModel(folderId);

  const [error, setError] = useState('');

  useEffect(() => {
    setFolderId(folderId);
  }, [folderId, setFolderId]);

  if (!isVisible) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (roomName.trim() === '') {
      setError('Room name is required');
      return;
    }
    setError('');
    try {
      await handleCreateRoom();
      onClose();
    } catch (error) {
      setError('Failed to create room. Please try again.');
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <h3>Create New Channel</h3>
        
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Channel Name</label>
            <input 
              type="text" 
              onChange={(e) => setRoomName(e.target.value)}
              placeholder="Enter channel name"
              maxLength={20}
            />
            {error && <p className={styles.error}>{error}</p>}
          </div>
          
          <div className={styles.formGroup}>
            <label>Description <span>(optional)</span></label>
            <input 
              type="text" 
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter channel description"
              maxLength={250}
            />
          </div>
          
          <div className={styles.buttons}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className={styles.createButton}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRoomModalView;