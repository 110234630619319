import { create } from 'zustand';
import { getSocket } from '../utils/socket';

import { getSpaceDetails, getFolders, getFolderContents, getChannelDetails, getChannelMessages, getLastActiveRoom, updateLastActiveRoom, updateLatestRoomActivity } from '../features/sidebar/services';
import { fetchDMList, getDMs, fetchUserGroupChats, fetchGroupChatMessages, fetchThreadMessages } from '../features/chat/services'
import useAuthStore from './authStore';
import useUserDetailsStore from './userDetailsStore';
import { decryptMessage } from '../services/messageEncryption';

const useSidebarStore = create((set, get) => ({
  socket: null, 
  folders: [],
  collapsedFolders: new Set(),
  folderContents: {},
  channelDetails: {},
  dmConversations: [],
  groupChats: [],
  messages: {},
  currentRoom: { room_id: '', room_type: '', receiver_id: '' },
  isDataLoaded: { folderContents: false }, 
  spaceDetails: {},
  userDetails: {},
  isDMCreationFlowActive: false,
  unreadRooms: new Set(),
  lastReadTimestamps: {},

  toggleDMCreationFlow: () => set(state => ({ isDMCreationFlowActive: !state.isDMCreationFlowActive })),

  initializeSocket: () => {
    if (get().socket) return;

    const { userID, spaceID } = useAuthStore.getState();
    const socket = getSocket();
    set({ socket });

    socket.on('connect', () => {
      // Send user status to server
      socket.emit('user_status', { userID, status: 'online' });

      const currentRoom = get().currentRoom;
      if (currentRoom.room_id) {
        socket.emit('join_room', currentRoom.room_id, currentRoom.room_type);
      }
    });

    socket.emit('join_user_channel', userID);
    
    socket.emit('join_space', spaceID);

    socket.on('online_users', (onlineUsers) => {
      const userDetailsStore = useUserDetailsStore.getState();
      onlineUsers.forEach(userID => {
        userDetailsStore.updateUserStatus(userID, 'online');
      });
    });

    socket.on('user_status_change', (data) => {
      useUserDetailsStore.getState().updateUserStatus(data.userID, data.status);
    });
  
    socket.on('receive_message', async (data) => {
      const { userID, spaceID } = useAuthStore.getState();
      const currentRoom = get().currentRoom;

      // Decrypt the received message
      const decryptedMessage = {
        ...data,
        message: await decryptMessage(userID, spaceID, data.message)
      };

      get().addMessage(data.room, decryptedMessage);

      if (currentRoom.room_id !== data.room) {
        get().checkForUnread(data.room, data.created_at);
      }
    });
    
    socket.on('user_notification', (data) => {
      const currentRoom = get().currentRoom; 
      
      // Only process notification if we're not in this room
      if (currentRoom.room_id !== data.room) {
        get().checkForUnread(data.room, data.timestamp);
      }
    });

    socket.on('parent_thread_updated', (updatedMessage) => {
      get().updateMessage(updatedMessage.parent_room_id, updatedMessage);
    });

    socket.on('room_created', ({ spaceId, folderId, channelData }) => {
      // Only update if it's for the current space
      if (spaceId === spaceID) {
        get().addChannelToFolder(folderId, channelData);
      }
    });

    socket.on('room_deleted', (data) => {
      const store = get();
      
      // Remove the channel locally
      store.removeChannelFromFolder(data.folderId, data.channelId);

      // If user is in the deleted channel, switch to the random channel
      if (store.currentRoom.room_id === data.channelId) {
        store.joinRoom(data.randomChannelId, 'channel', null, false);
      }
    });

    socket.on('folder_created', ({ spaceId, folderData }) => {
      // Only update if it's for the current space
      if (spaceId === spaceID) {
        get().addFolder({
          folder_id: folderData.folderId,
          folder_name: folderData.folderName
        });
      }
    });

    socket.on('folder_deleted', ({ spaceId, folderId, channelsToMove }) => {
      if (spaceId === spaceID) {
        // Remove folder
        get().removeFolder(folderId);
        
        // Add channels to SIDEBAR using the provided channel data
        channelsToMove.forEach(channel => {
          get().addChannelToFolder('SIDEBAR', channel);
        });
      }
    });

    socket.on('disconnect', () => {
      // Send user status to server
      socket.emit('user_status', { userID, status: 'away' });

      set({ socket: null });
    });

    socket.on('reaction_added', (data) => {
      const { messageId, roomId, reaction, userId } = data;
      const message = get().messages[roomId]?.find(msg => msg.message_id === messageId);
      
      if (message) {
        if (!message.reactions) message.reactions = {};
        if (!message.reactions[reaction]) message.reactions[reaction] = [];
        if (!message.reactions[reaction].includes(userId)) {
          message.reactions[reaction].push(userId);
          get().updateMessage(roomId, message);
        }
      }
    });

    socket.on('reaction_removed', (data) => {
      const { messageId, roomId, reaction, userId } = data;
      const message = get().messages[roomId]?.find(msg => msg.message_id === messageId);
      
      if (message && message.reactions?.[reaction]) {
        message.reactions[reaction] = message.reactions[reaction].filter(id => id !== userId);
        if (message.reactions[reaction].length === 0) {
          delete message.reactions[reaction];
        }
        get().updateMessage(roomId, message);
      }
    });
  },

  joinRoom: (roomId, roomType, receiver_id, isFromSidebar = true) => {
    const currentRoom = get().currentRoom;
    
    // Check if we're trying to join the same room
    if (currentRoom.room_id === roomId && currentRoom.room_type === roomType) {
      return;
    }

    // Check if DM creation flow is active and turn it off
    if (isFromSidebar && get().isDMCreationFlowActive) {
      get().toggleDMCreationFlow();
    }
    
    get().leaveRoom();
  
    const socket = get().socket;
    if (socket) {
      socket.emit('join_room', roomId, roomType);
      set({ currentRoom: { room_id: roomId, room_type: roomType, receiver_id } }); 
  
      const { userID, spaceID } = useAuthStore.getState();
      get().updateLastActiveRoom(userID, spaceID, roomId, roomType);
    }
  },

  leaveRoom: () => {
    const socket = get().socket;
    const currentRoom = get().currentRoom;
  
    if (socket && currentRoom.room_id) {  
      // Mark the room as read when leaving
      get().markRoomAsRead(currentRoom.room_id);
      
      socket.emit('leave_room', currentRoom.room_id);

      set(state => ({
        currentRoom: { room_id: '', room_type: '', receiver_id: '' },
        messages: { ...state.messages, [currentRoom.room_id]: [] }
      }));
    }
  },

  fetchLastActiveRoom: async (userID, spaceID) => {
    try {
      const { roomId, roomType } = await getLastActiveRoom(userID, spaceID);
      set({ currentRoom: { room_id: roomId, room_type: roomType } });
    } catch (error) {
      console.error("Error fetching last active room:", error);
    }
  },
  
  updateLastActiveRoom: async (userID, spaceID, roomID, roomType) => {
    try {
      await updateLastActiveRoom(userID, spaceID, roomID, roomType);
    } catch (error) {
      console.error("Error updating last active room:", error);
    }
  },

  fetchSpaceDetails: async (spaceId) => {
    try {
      const spaceDetails = await getSpaceDetails(spaceId);
      set({ spaceDetails: spaceDetails });
    } catch (error) {
      console.error("Error fetching space details:", error);
    }
  },

  fetchDMConversations: async (spaceId, userID) => {
    try {
      const dms = await fetchDMList(spaceId, userID);
      set({ dmConversations: dms });
    } catch (error) {
      console.error("Error fetching personal DMs:", error);
    }
  },

  fetchGroupChats: async (spaceId, userID) => {
    try {
      const groupChats = await fetchUserGroupChats(spaceId, userID);
      set({ groupChats: groupChats });
    } catch (error) {
      console.error("Error fetching personal group chats:", error);
    }
  },

  fetchMessages: async (roomId, roomType, before, limit) => {
    const { userID, spaceID } = useAuthStore.getState();
    let fetchedMessages;

    try {
      switch (roomType) {
        case 'channel':
          fetchedMessages = await getChannelMessages(spaceID, roomId, before, limit);
          break;
        case 'group_chat':
          fetchedMessages = await fetchGroupChatMessages(spaceID, roomId, before, limit);
          break;
        case 'dm':
          fetchedMessages = await getDMs(spaceID, roomId, before, limit);
          break;
        case 'thread':
          fetchedMessages = await fetchThreadMessages(spaceID, roomId, before, limit);
          break;
        default:
          throw new Error('Invalid room type');
      }

      // Decrypt messages
      const decryptedMessages = await Promise.all(
        fetchedMessages.map(async (msg) => ({
          ...msg,
          message: await decryptMessage(userID, spaceID, msg.message)
        }))
      );

      // Fetch user details for all unique sender_ids
      const uniqueSenderIds = [...new Set(decryptedMessages.map(msg => msg.sender_id))];
      
      await Promise.all(uniqueSenderIds.map(senderId => 
        useUserDetailsStore.getState().fetchUserDetails(senderId, spaceID)
      ));

      set(state => {
        const newMessages = { ...state.messages };
        const existingMessages = newMessages[roomId] || [];
        
        const existingMessageIds = new Set(existingMessages.map(msg => msg.message_id));
        
        const uniqueNewMessages = decryptedMessages
          .filter(msg => !existingMessageIds.has(msg.message_id))
          .map(msg => ({
            ...msg,
            attachments: Array.isArray(msg.attachments) ? msg.attachments : [],
            reply_count: msg.reply_count || 0,
            last_reply_at: msg.last_reply_at || null,
            has_replies: msg.reply_count > 0
          }));

        const combinedMessages = [...uniqueNewMessages, ...existingMessages].sort((a, b) => 
          new Date(a.created_at) - new Date(b.created_at)
        );

        newMessages[roomId] = combinedMessages;
        return { messages: newMessages };
      });

    } catch (error) {
      console.error('Error fetching messages:', error);
      throw error;
    }
  },

  setMessages: (messages) => set({ messages }),

  addMessage: (roomId, message) => { 
    set(state => {
      const newMessages = { ...state.messages };
      const roomMessages = newMessages[roomId] || [];
      
      // Check if the message already exists by message_id
      const messageExists = roomMessages.find(msg => msg.message_id === message.message_id);
      
      if (!messageExists) {
        newMessages[roomId] = [...roomMessages, message];
      }
  
      return { messages: newMessages };
    });
  },

  updateMessage: (roomId, updatedMessage) => {
    set(state => {
      const newMessages = { ...state.messages };
      const roomMessages = newMessages[roomId] || [];
      const messageIndex = roomMessages.findIndex(msg => msg.message_id === updatedMessage.message_id);
      
      if (messageIndex !== -1) {
        roomMessages[messageIndex] = updatedMessage;
        newMessages[roomId] = roomMessages;
      }
  
      return { messages: newMessages };
    });
  },

  fetchFoldersForSpace: async (spaceId) => {
    try {
      // Fetch folders for the given space
      const folders = await getFolders(spaceId);

      // Initialize objects to store folder contents and channel details
      const folderContents = {};
      const channelDetails = {};
  
      // Fetch contents for each folder and channel details concurrently
      await Promise.all(folders.map(async ({ folder_id }) => {
        // Get contents for the current folder
        folderContents[folder_id] = await getFolderContents(spaceId, folder_id);
        
        // Fetch channel details for items in the folder that are channels
        await Promise.all(folderContents[folder_id].map(async ({ channel_id }) => {
          if (!channelDetails[channel_id]) {
            channelDetails[channel_id] = await getChannelDetails(spaceId, channel_id);
          }
        }));
      }));
  
      // Update the state with all fetched data
      set(state => ({
        folders,
        folderContents,
        channelDetails: { ...state.channelDetails, ...channelDetails },
        isDataLoaded: { ...state.isDataLoaded, folderContents: true },
      }));
    } catch (error) {
      console.error("Error fetching folders and contents:", error);
    }
  },

  toggleFolder: (folderId) => {
    set(state => {
      const newCollapsedFolders = new Set(state.collapsedFolders);
      if (newCollapsedFolders.has(folderId)) {
        newCollapsedFolders.delete(folderId);
      } else {
        newCollapsedFolders.add(folderId);
      }
      return { collapsedFolders: newCollapsedFolders };
    });
  },

  addFolder: (folder) => {
    set(state => ({
      folders: [...state.folders, folder],
      collapsedFolders: new Set(state.collapsedFolders)  
    }));
  },

  removeFolder: (folderId) => {
    set(state => {
      const newCollapsedFolders = new Set(state.collapsedFolders);
      newCollapsedFolders.delete(folderId);  
  
      return {
        folders: state.folders.filter(folder => folder.folder_id !== folderId),
        collapsedFolders: newCollapsedFolders
      };
    });
  },

  addChannelToFolder: (folderId, channelData) => {
    set(state => {
      const updatedFolderContents = {
        ...state.folderContents,
        [folderId]: [
          ...(state.folderContents[folderId] || []),
          { 
            space_id: channelData.spaceId, 
            folder_id: folderId, 
            channel_id: channelData.channelId, 
            order: state.folderContents[folderId]?.length || 0  
          }
        ]
      };

      const updatedChannelDetails = {
        ...state.channelDetails,
        [channelData.channelId]: {
          channel_name: channelData.channelName,
          channel_description: channelData.channelDescription,
          channel_id: channelData.channelId,
          space_id: channelData.spaceId,
          folder_id: folderId,
          created_at: new Date().toISOString(),
        }
      };

      return {
        folderContents: updatedFolderContents,
        channelDetails: updatedChannelDetails,
      };
    });
  },

  removeChannelFromFolder: (folderId, channelId) => {
    set(state => {
      const updatedFolderContents = {
        ...state.folderContents,
        [folderId]: state.folderContents[folderId].filter(item => item.channel_id !== channelId),
      };

      const updatedChannelDetails = { ...state.channelDetails };
      delete updatedChannelDetails[channelId];

      return {
        folderContents: updatedFolderContents,
        channelDetails: updatedChannelDetails,
      };
    });
  },

  markRoomAsRead: async (roomId) => {
    const timestamp = new Date().toISOString();
    const currentRoom = get().currentRoom;
    
    if (get().unreadRooms.has(roomId)) {
      set(state => ({
        unreadRooms: new Set([...state.unreadRooms].filter(id => id !== roomId)),
        lastReadTimestamps: { ...state.lastReadTimestamps, [roomId]: timestamp }
      }));

      const { userID, spaceID } = useAuthStore.getState();
      await updateLatestRoomActivity(spaceID, userID, roomId, currentRoom.room_type, timestamp);
    }
  },

  checkForUnread: async (roomId, messageTimestamp) => {
    const lastRead = get().lastReadTimestamps[roomId];
    const currentRoom = get().currentRoom;
    
    if (currentRoom.room_id === roomId) {
      set(state => ({
        lastReadTimestamps: { ...state.lastReadTimestamps, [roomId]: messageTimestamp },
        unreadRooms: new Set([...state.unreadRooms].filter(id => id !== roomId))
      }));
      
      const { userID, spaceID } = useAuthStore.getState();
      await updateLatestRoomActivity(spaceID, userID, roomId, currentRoom.room_type, messageTimestamp);
      return;
    }
    
    if (!lastRead || new Date(messageTimestamp) > new Date(lastRead)) {
      set(state => ({
        unreadRooms: new Set([...state.unreadRooms, roomId])
      }));

      const { userID, spaceID } = useAuthStore.getState();
      await updateLatestRoomActivity(spaceID, userID, roomId, currentRoom.room_type, lastRead || new Date(0).toISOString());
    }
  },

}));

export default useSidebarStore;