import React, { useState } from 'react';
import styles from './HyperlinkView.module.css';

const HyperlinkView = ({ onClose, onInsert }) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const displayText = text.trim() || url;
    onInsert(`[${displayText}](${url})`);
    onClose();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>Insert Link</h3>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label>Link Text (optional)</label>
            <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Display text"
            />
          </div>
          <div className={styles.inputGroup}>
            <label>URL</label>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com"
              maxLength={250}
              required
            />
          </div>
          <div className={styles.buttonGroup}>
            <button type="button" onClick={onClose}>Cancel</button>
            <button type="submit">Insert</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HyperlinkView;