import React from 'react';
import styles from './KeyManagementView.module.css';

const KeyManagementView = () => {
  return (
    <div className={styles.container}>
      <p>coming soon</p>
    </div>
  );
};

export default KeyManagementView;