import React, { useState } from 'react';
import styles from './MessageActions.module.css';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

const MessageActions = ({ onReplyClick, isInThreadSidebar, message, onReactionSelect }) => {
  const [showReactionPicker, setShowReactionPicker] = useState(false);

  const handleReactionClick = () => {
    setShowReactionPicker(!showReactionPicker);
  };

  return (
    <div className={styles.messageActions}>
      {!isInThreadSidebar && (
        <button className={styles.actionButton} id="reply-button" onClick={onReplyClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 19v-4q0-1.25-.875-2.125T16 12H6.825l3.6 3.6L9 17l-6-6l6-6l1.425 1.4l-3.6 3.6H16q2.075 0 3.538 1.463T21 15v4z"/></svg>
        </button>
      )}
      
      <button className={styles.actionButton} onClick={handleReactionClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.15em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M22 11v1a10 10 0 1 1-9-10"/><path d="M8 14s1.5 2 4 2s4-2 4-2M9 9h.01M15 9h.01M16 5h6m-3-3v6"/></g></svg>
      </button>

      {showReactionPicker && (
        <div className={styles.emojiPickerPopup}>
          <Picker 
            data={data} 
            onEmojiSelect={(emoji) => {
              onReactionSelect(emoji.native);
              setShowReactionPicker(false);
            }}
            theme="dark"
            previewPosition="none"
            skinTonePosition="none"
            searchPosition="none"
            maxFrequentRows={2}
          />
        </div>
      )}

      <button className={styles.actionButton}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.25em" viewBox="0 0 24 24"><path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3m1 17.27l-4.5-2.6a1 1 0 0 0-1 0L7 19.27V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1Z"/></svg>
      </button>

      <button className={styles.actionButton}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0-6a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0-6a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2"/></svg>
      </button>
    </div>
  );
};

export default MessageActions;