import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import useAuthStore from '../../../store/authStore';
import styles from './JoinSpaceView.module.css';
import { validateKeyFile, storeWorkspaceKeys } from '../utils/keyFileHandler';

import { updateRole, updateUser, removeSpaceInvite } from '../../onboarding/services/onboardingService';
import { processJoinCode } from '../services/onboardingService';

import { generateAndRegisterUserKeys } from '../../../services/keyGenerationService';
import KeyGenerationLoadingView from '../../encryption/KeyGenerationLoadingView';

const JoinSpaceView = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [keyFile, setKeyFile] = useState(null);
  const navigate = useNavigate();

  const { joinCode } = useParams();
  const { establishUserSpace, setOnboardingStatus, userID, userEmail, isAuthenticated } = useAuthStore();
  const [isGeneratingKeys, setIsGeneratingKeys] = useState(false);

  useEffect(() => {
    const validateJoinFlow = async () => {
      if (!isAuthenticated) {
        navigate('/login', { state: { from: `/invite/${joinCode}` }, replace: true });
        return;
      }

      try {
        const spaceID = await processJoinCode(joinCode, userEmail);
        
        if (!spaceID) {
          setError('Invalid or expired invite link');
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceID}/get-space-details`);
        const details = await response.json();
        
        setSpaceDetails(details);
      } catch (error) {
        console.error('Error validating join code:', error);
        setError('This invitation is not valid for your email address');
      } finally {
        setLoading(false);
      }
    };

    validateJoinFlow();
  }, [joinCode, isAuthenticated, navigate, userEmail]);

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      await validateKeyFile(file);
      setKeyFile(file);
      setError(null);
    } catch (error) {
      setError('Invalid key file. Please ensure you uploaded the correct file.');
      setKeyFile(null);
    }
  };

  const handleContinue = async () => {
    if (!keyFile || !spaceDetails) return;
    setIsGeneratingKeys(true);

    try {
      // First validate and store the workspace keys from the file
      const keys = await validateKeyFile(keyFile);
      await storeWorkspaceKeys(userID, spaceDetails.space_id, keys);

      // Generate identity key and backup file
      await generateAndRegisterUserKeys(userID, spaceDetails.space_id, spaceDetails.space_name);

      // Update user role and workspace
      await updateRole(userID, spaceDetails.space_id, 'member');
      await updateUser(userEmail, spaceDetails.space_id);
      
      // Remove the space invite after successful joining
      await removeSpaceInvite(joinCode, userEmail);
      
      establishUserSpace(spaceDetails.space_id, 'member');
      setOnboardingStatus('create/profile');
      navigate('/create/profile', { replace: true });
    } catch (error) {
      console.error('Error joining space:', error);
      setError('Failed to join space. Please try again.');
      setIsGeneratingKeys(false);
    }
  };

  const handleBackToMention = () => {
    navigate('/', { replace: true });
  };

  if (isGeneratingKeys) {
    return <KeyGenerationLoadingView />;
  }

  if (loading) {
    return <div className={styles.container}>Loading...</div>;
  }

  if (error) {
    return (
      <div className={styles.container}>
        <h2 className={styles.error}>{error}</h2>
        <button onClick={handleBackToMention} className={styles.backButton}>
          Back to Mention
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.spaceCard}>
        {spaceDetails.space_image ? (
          <img 
            src={spaceDetails.space_image_url} 
            alt={spaceDetails.space_name} 
            className={styles.spaceImage}
          />
        ) : (
          <Avatar 
            name={spaceDetails.space_name} 
            size="100" 
            round={true}
          />
        )}
        <h2>Join {spaceDetails.space_name}</h2>
        <p>{spaceDetails.member_count || 0} members</p>
        
        <div className={styles.keyFileUpload}>
          <p>Please upload the workspace keys file you received via email:</p>
          <input
            type="file"
            accept=".json"
            onChange={handleFileUpload}
            className={styles.fileInput}
          />
        </div>
        
        <div className={styles.buttonContainer}>
          <button 
            onClick={handleContinue} 
            className={styles.continueButton}
            disabled={!keyFile}
          >
            Continue
          </button>
          <button onClick={handleBackToMention} className={styles.backButton}>
            Back to Mention
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinSpaceView;
