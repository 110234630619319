// Generate PEM formatted backup content for identity keys
function generateBackupFileContent(workspaceId, spaceName, identityKey) {
  // Helper function to convert ArrayBuffer to base64
  const arrayBufferToBase64 = (buffer) => {
    const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
    return window.btoa(binary);
  };

  const privateKey = arrayBufferToBase64(identityKey.privKey);
  const publicKey = arrayBufferToBase64(identityKey.pubKey);
  
  return `
    -----BEGIN WORKSPACE INFO-----
    Workspace: ${workspaceId}
    Created: ${new Date().toISOString()}
    -----END WORKSPACE INFO-----
    -----BEGIN PRIVATE KEY-----
    ${privateKey}
    -----END PRIVATE KEY-----
    -----BEGIN PUBLIC KEY-----
    ${publicKey}
    -----END PUBLIC KEY-----
  `;
}

// Auto-download the backup file
function autoDownloadBackupFile(filename, content) {
  const blob = new Blob([content], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export { generateBackupFileContent, autoDownloadBackupFile };