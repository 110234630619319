import { useCallback, useRef, useMemo } from 'react';
import useUserDetailsStore from '../../store/userDetailsStore';
import useUIStore from '../user/useUIStore';

const useMessageViewModel = (message) => {
  const getBasicUserDetails = useUserDetailsStore(state => state.getBasicUserDetails);
  const {
    activeQuickViewUserId,
    quickViewPosition,
    isModalOpen,
    modalUserId,
    setActiveQuickView,
    closeQuickView,
    openModal,
    closeModal,
    setHoveringTrigger,
  } = useUIStore();

  const avatarRef = useRef(null);
  const userInfoRef = useRef(null);
  
  const userDetails = useMemo(() => 
    getBasicUserDetails(message.sender_id) || {}
  , [getBasicUserDetails, message.sender_id]);

  const handleUserClick = useCallback(() => {
    openModal(message.sender_id);
  }, [openModal, message.sender_id]);

  const handleMouseEnter = useCallback((ref) => {
    if (userDetails) {
      const rect = ref.current.getBoundingClientRect();
      setActiveQuickView(message.sender_id, {
        top: rect.top - 95,
        left: rect.left,
      });
      setHoveringTrigger(true);
    }
  }, [userDetails, message.sender_id, setActiveQuickView, setHoveringTrigger]);

  const handleMouseLeave = useCallback(() => {
    setHoveringTrigger(false);
  }, [setHoveringTrigger]);

  const formatTimestamp = (timestamp) => {
    const date = timestamp ? new Date(timestamp) : new Date();

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  // const formatMessage = (text) => {
  //   const boldRegex = /\*\*(.*?)\*\*/g;
  //   const italicRegex = /\*(.*?)\*/g;
  //   const linkRegex = /(https?:\/\/[^\s]+)/g;

  //   return text
  //     .replace(boldRegex, "<strong>$1</strong>")
  //     .replace(italicRegex, "<em>$1</em>")
  //     .replace(linkRegex, '<a href="$1" target="_blank">$1</a>');
  // };

  const timestamp = formatTimestamp(message.created_at);
  const displayName = userDetails?.displayName || 'Loading...';
  const imageUrl = userDetails?.imageUrl || 'default-avatar.png';

  return {
    avatarRef,
    userInfoRef,
    userDetails,
    displayName,
    imageUrl,
    timestamp,
    activeQuickViewUserId,
    quickViewPosition,
    isModalOpen,
    modalUserId,
    handleUserClick,
    handleMouseEnter,
    handleMouseLeave,
    closeQuickView,
    closeModal,
  };
};

export default useMessageViewModel;
