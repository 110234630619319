import { generateBackupFileContent, autoDownloadBackupFile } from '../utils/backupKeyFile';
import { storeWorkspaceIdentityKey, storeWorkspaceSignedPreKey, storeWorkspaceOneTimePreKeys } from './indexedDBService';
import { uploadPublicIdentityKey, uploadPublicSignedPreKey, uploadPublicOneTimePreKey } from './publicKeyUploadService';
import { extractPublicKey } from '../utils/keyUtils';

import * as libsignal from '@privacyresearch/libsignal-protocol-typescript';

async function generateIdentityKey() {
  return await libsignal.KeyHelper.generateIdentityKeyPair();
}

async function generateSignedPreKeys(identityKey) {
  const signedPreKeyId = Math.floor(Math.random() * 16777215);
  const signedPreKey = await libsignal.KeyHelper.generateSignedPreKey(identityKey, signedPreKeyId);

  return { signedPreKey };
}

async function generateOneTimePreKeys() {
  const oneTimePreKeys = [];
  for (let i = 0; i < 100; i++) {
    const preKey = await libsignal.KeyHelper.generatePreKey(i);
    oneTimePreKeys.push(preKey);
  }
  return { oneTimePreKeys };
}

async function registerWorkspaceIdentityKey(userId, workspaceId, identityKey) {
  await storeWorkspaceIdentityKey(userId, workspaceId, identityKey);
  const publicKey = await extractPublicKey(identityKey);
  await uploadPublicIdentityKey(userId, workspaceId, publicKey);
}

async function registerWorkspaceSignedPreKey(userId, workspaceId, signedPreKey) {
  await storeWorkspaceSignedPreKey(userId, workspaceId, signedPreKey);

  // Extract necessary information
  const keyId = signedPreKey.signedPreKey.keyId;
  const publicKey = extractPublicKey(signedPreKey);
  const signature = btoa(String.fromCharCode.apply(null, new Uint8Array(signedPreKey.signedPreKey.signature)));

  // Upload to ScyllaDB with workspace context
  await uploadPublicSignedPreKey(userId, workspaceId, keyId, publicKey, signature);
}

async function registerWorkspaceOneTimePreKeys(userId, workspaceId, { oneTimePreKeys }) {
  await storeWorkspaceOneTimePreKeys(userId, workspaceId, oneTimePreKeys);

  // Upload public keys to ScyllaDB
  for (const preKey of oneTimePreKeys) {
    await uploadPublicOneTimePreKey(userId, workspaceId, preKey.keyId, extractPublicKey(preKey.keyPair));
  }
}

async function generateAndRegisterUserKeys(userId, workspaceId, spaceName) {
  const identityKey = await generateIdentityKey();
  await registerWorkspaceIdentityKey(userId, workspaceId, identityKey);
  
  const backupContent = generateBackupFileContent(workspaceId, spaceName, identityKey);
  const safeSpaceName = spaceName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  const fileName = `mention-${safeSpaceName}-${workspaceId}_key_backup.pem`;
  autoDownloadBackupFile(fileName, backupContent);
}

export { 
  generateIdentityKey, 
  generateSignedPreKeys, 
  generateOneTimePreKeys, 
  registerWorkspaceIdentityKey,
  registerWorkspaceSignedPreKey,
  registerWorkspaceOneTimePreKeys,
  generateAndRegisterUserKeys,
};