import React, { useState, useEffect } from 'react';
import styles from './LinkPreview.module.css';
import { fetchLinkPreview } from './services/linkPreviewService';
const LinkPreview = ({ url }) => {
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [faviconUrl, setFaviconUrl] = useState(null);

  useEffect(() => {
    const getMetadata = async () => {
      try {
        setLoading(true);
        const data = await fetchLinkPreview(url);
        if (data) {
          setMetadata({
            title: data.title || 'No title available',
            description: data.description || 'No description available',
            favicon: data.favicon || null
          });
          if (data.favicon) {
            setFaviconUrl(data.favicon);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
   
    getMetadata();
  }, [url]);

  const handleFaviconError = () => {
    // If the favicon fails to load, use Google's favicon service as a fallback
    setFaviconUrl(`https://www.google.com/s2/favicons?domain=${encodeURIComponent(url)}`);
  };

  if (loading) return <div>Loading preview...</div>;
  if (!metadata) return null;

  return (
    <div className={styles.linkPreviewWrapper}>
      <div className={styles.linkPreview}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <div className={styles.previewContent}>
            <div className={styles.previewText}>
              <div className={styles.previewHeader}>
                {faviconUrl && (
                  <img 
                    src={faviconUrl} 
                    alt="Site favicon" 
                    className={styles.previewFavicon}
                    onError={handleFaviconError}
                  />
                )}
                <h3 className={styles.previewTitle}>{metadata.title || 'No title available'}</h3>
              </div>
              <div className={styles.previewDescription}>{metadata.description || 'No description available'}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default LinkPreview;