import React, { useState, useRef, useEffect } from 'react';
import styles from './EditProfileModal.module.css';
import { FaCamera, FaSpinner } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';
import { updateUserProfile } from './services/userService';
import useAuthStore from '../../store/authStore';
import useUserDetailsStore from '../../store/userDetailsStore';

const EditProfileModal = ({ userDetails, onClose }) => {
  const [displayName, setDisplayName] = useState(userDetails.userSpaceDetails.user_display_name);
  const [bio, setBio] = useState(userDetails.userSpaceDetails.user_bio);
  const [userImage, setUserImage] = useState(userDetails.userSpaceDetails.user_image_url);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);
  
  const userID = useAuthStore(state => state.userID);
  const spaceID = useAuthStore(state => state.spaceID);

  useEffect(() => {
    const hasDisplayNameChanged = displayName !== userDetails.userSpaceDetails.user_display_name;
    const hasBioChanged = bio !== userDetails.userSpaceDetails.user_bio;
    const hasImageChanged = userImage !== userDetails.userSpaceDetails.user_image_url;
    
    setHasChanges(hasDisplayNameChanged || hasBioChanged || hasImageChanged);
  }, [displayName, bio, userImage, userDetails.userSpaceDetails]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsImageLoading(true);
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setUserImage(reader.result);
          setIsImageLoading(false);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error handling image:', error);
        setIsImageLoading(false);
        setError('Failed to process image');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!displayName.trim()) {
      setError('Display name is required');
      return;
    }

    try {
      const updates = {};
      if (displayName !== userDetails.userSpaceDetails.user_display_name) {
        updates.displayName = displayName;
      }
      if (bio !== userDetails.userSpaceDetails.user_bio) {
        updates.bio = bio;
      }
      if (userImage !== userDetails.userSpaceDetails.user_image_url) {
        updates.image = userImage;
      }

      const response = await updateUserProfile(userID, spaceID, updates);
      
      if (response.userDetails) {
        useUserDetailsStore.getState().updateUserDetails(userID, {
          user_display_name: response.userDetails.user_display_name,
          user_bio: response.userDetails.user_bio,
          user_image_url: response.userDetails.user_image
        });
      }
      
      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  return (
    <div className={styles.overlay} onClick={e => e.target === e.currentTarget && onClose()}>
      <div className={styles.modal}>
        <h3>Edit Profile</h3>
        
        <form onSubmit={handleSubmit}>
          <div className={styles.imageSection}>
            <div className={styles.imageContainer} onClick={handleImageClick}>
              {isImageLoading ? (
                <div className={styles.loadingContainer}>
                  <FaSpinner className={styles.spinner} />
                </div>
              ) : (
                <>
                  <img 
                    src={userImage || '/default-avatar.png'} 
                    alt="Profile" 
                    className={styles.profileImage}
                  />
                  <div className={styles.uploadOverlay}>
                    <FaCamera />
                    <span>Change Avatar</span>
                  </div>
                </>
              )}
            </div>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Display Name</label>
            <input
              type="text"
              value={displayName}
              onChange={e => setDisplayName(e.target.value)}
              placeholder="Enter your display name"
              maxLength={20}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Bio</label>
            <textarea
              value={bio}
              onChange={e => setBio(e.target.value)}
              placeholder="Tell us about yourself"
              maxLength={250}
            />
          </div>

          {error && <p className={styles.error}>{error}</p>}

          <div className={styles.buttons}>
            <button type="button" onClick={onClose} className={styles.cancelButton}>
              Cancel
            </button>
            <button 
              type="submit" 
              className={styles.saveButton}
              disabled={!hasChanges}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfileModal;