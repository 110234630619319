import { useState, useEffect } from 'react';

import useUserDetailsStore from '../../../../store/userDetailsStore';
import useSidebarStore from '../../../../store/sidebarStore';
import useAuthStore from '../../../../store/authStore';
import { fetchUserWorkspaces } from '../../../../services/WorkspaceService';
import { updateLastActiveSpace } from '../../../../features/onboarding/services/onboardingService';

import styles from './Sidebar.module.css'; 

const useSidebarViewModel = () => {
  const userRole = useAuthStore((state) => state.userRole);
  const folders = useSidebarStore(state => state.folders);
  const toggleFolder = useSidebarStore(state => state.toggleFolder);
  const channelDetails = useSidebarStore(state => state.channelDetails);
  const collapsedFolders = useSidebarStore(state => state.collapsedFolders);
  const folderContents = useSidebarStore(state => state.folderContents);
  const currentRoom = useSidebarStore(state => state.currentRoom);
  const moveFolder = useSidebarStore(state => state.moveFolder);
  const joinRoom = useSidebarStore(state => state.joinRoom);
  const dmConversations = useSidebarStore(state => state.dmConversations);
  const groupChats = useSidebarStore(state => state.groupChats);
  const toggleDMCreationFlow = useSidebarStore(state => state.toggleDMCreationFlow);
  const unreadRooms = useSidebarStore(state => state.unreadRooms);
  const userID = useAuthStore(state => state.userID);
  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);

  const spaceDetails = useSidebarStore(state => state.spaceDetails);
  const userDetails = useUserDetailsStore(state => state.userDetails.get(userID));
  
  const getUserStatus = useUserDetailsStore(state => state.getUserStatus);
  const isOnline = getUserStatus(userID) === 'online';

  const [contextMenu, setContextMenu] = useState(null);
  const [fadingOut, setFadingOut] = useState(false);
  const [isCreateFolderModalVisible, setCreateFolderModalVisible] = useState(false);
  const [isCreateRoomModalVisible, setCreateRoomModalVisible] = useState(false);
  const [addNewContextMenu, setAddNewContextMenu] = useState(null);

  // Add workspace-related state
  const [showSpaceDropdown, setShowSpaceDropdown] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const { userEmail, establishUserSpace, setOnboardingStatus } = useAuthStore();

  // Load workspaces
  useEffect(() => {
    const loadWorkspaces = async () => {
      try {
        const spaces = await fetchUserWorkspaces(userEmail);
        setWorkspaces(spaces);
      } catch (error) {
        console.error("Error loading workspaces:", error);
      }
    };

    loadWorkspaces();
  }, [userEmail]);

  const handleSpaceSwitch = async (spaceId) => {
    try {
      await updateLastActiveSpace(userEmail, spaceId);
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}space/initialize-space?userID=${userID}&spaceID=${spaceId}`,
        { credentials: 'include' }
      );
      
      if (!response.ok) {
        throw new Error('Failed to initialize space');
      }
      
      const { userRole, onboardingStatus } = await response.json();
      
      establishUserSpace(spaceId, userRole);
      setOnboardingStatus(onboardingStatus);
      setShowSpaceDropdown(false);
      
      window.location.href = `/client/${spaceId}`; // Force reload
    } catch (error) {
      console.error("Error switching workspace:", error);
    }
  };

  // Add click outside handler for workspace dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.space__info_container}`)) {
        setShowSpaceDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenu && !event.target.closest(`.${styles.contextMenu}`)) {
        setFadingOut(true);
        setTimeout(() => {
          setContextMenu(null);
          setFadingOut(false);
        }, 300);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addNewContextMenu && !event.target.closest(`.${styles.contextMenu}`)) {
        setFadingOut(true);
        setTimeout(() => {
          setAddNewContextMenu(null);
          setFadingOut(false);
        }, 300);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addNewContextMenu]);

  const handleContextMenu = (event, folderId = null, roomId = null) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu({ mouseX: event.clientX, mouseY: event.clientY, folderId, roomId });
  };
  
  const closeContextMenu = () => {
    setFadingOut(true);
    setTimeout(() => {
      setContextMenu(null);
      setFadingOut(false);
    }, 300);
  }; 

  return {
    userRole,
    folders,
    userID,
    toggleFolder,
    folderContents,
    contextMenu,
    handleContextMenu, 
    closeContextMenu,
    fadingOut,
    moveFolder,
    joinRoom,
    currentRoom,
    isCreateFolderModalVisible,
    setCreateFolderModalVisible,
    isCreateRoomModalVisible,
    setCreateRoomModalVisible,
    dmConversations,
    collapsedFolders,
    groupChats,
    channelDetails,
    spaceDetails,
    userDetails,
    toggleDMCreationFlow,
    unreadRooms,
    isOnline,
    fetchUserDetails,
    addNewContextMenu,
    setAddNewContextMenu,
    showSpaceDropdown,
    setShowSpaceDropdown,
    workspaces,
    handleSpaceSwitch,
  }; 
};

export default useSidebarViewModel;