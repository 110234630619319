const fetchUserWorkspaces = async (email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-user-workspaces?email=${encodeURIComponent(email)}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch workspaces");
    }

    const data = await response.json();
    console.log(data);
    return data.workspaces.map(workspace => ({
      ...workspace,
      imageUrl: workspace.image_url || '/default-avatar.png',
      description: workspace.description || 'No description available'
    })) || [];
  } catch (error) {
    console.error("Error fetching workspaces:", error);
    return [];
  }
}; 

export { fetchUserWorkspaces }; 