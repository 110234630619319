import React, { useState } from 'react';
import styles from './InviteEmailBox.module.css';

const InviteEmailBox = ({ emails, setEmails, onSendInvites, isLoading }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmail = (e) => {
    if ((e.key === 'Enter' || e.key === ' ' || e.key === ',') && inputValue.trim()) {
      e.preventDefault();
      const email = inputValue.trim();
      
      if (!isValidEmail(email)) {
        setError(`"${email}" is not a valid email address`);
        return;
      }

      if (emails.includes(email)) {
        setError('This email has already been added');
        return;
      }

      setEmails([...emails, email]);
      setInputValue('');
      setError('');
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
    setError('');
  };

  return (
    <div className={styles.inviteEmailBoxWrapper}>
      <div className={styles.inviteEmailBox}>
        {emails.map((email, index) => (
          <span key={index} className={styles.emailTag}>
            {email}
            <button 
              type="button" 
              onClick={() => handleDeleteEmail(email)} 
              className={styles.deleteButton}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="-6 -6 24 24">
                  <rect x="-6" y="-6" width="24" height="24" fill="none" />
                  <path fill="currentColor" d="m7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485L2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535l3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z" />
              </svg>
            </button>
          </span>
        ))}
      </div>

      <div className={styles.emailInputWrapper}>
        <textarea
          type="text"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setError('');
          }}
          onKeyDown={handleAddEmail}
          placeholder={emails.length === 0 ? "example@gmail.com" : ""}
          className={styles.emailInput}
        />
        {onSendInvites && (
          <button 
            className={styles.sendButton} 
            onClick={onSendInvites}
            disabled={isLoading || emails.length === 0}
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path fill="currentColor" d="M17.991 6.01L5.399 10.563l4.195 2.428l3.699-3.7a1 1 0 0 1 1.414 1.415l-3.7 3.7l2.43 4.194L17.99 6.01Z"/>
            </g>
          </svg>
          </button>
        )}
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

export default InviteEmailBox;