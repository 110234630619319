import { removeFolder } from "../../services/spaceService";
import { deleteChannel } from "../../services/folderService";
import useSidebarStore from '../../../../store/sidebarStore';
import useAuthStore from "../../../../store/authStore";

const deleteFolder = async (folderId) => {
  try {
    const { spaceID } = useAuthStore.getState();
    const sidebarStore = useSidebarStore.getState();
    const folderContents = sidebarStore.folderContents[folderId] || [];
    
    // Prepare channel data for socket event
    const channelsToMove = folderContents.map(channel => ({
      channelId: channel.channel_id,
      channelName: sidebarStore.channelDetails[channel.channel_id]?.channel_name,
      channelDescription: sidebarStore.channelDetails[channel.channel_id]?.channel_description,
      spaceId: spaceID
    }));

    // Update local state immediately
    sidebarStore.removeFolder(folderId);
    
    // Move channels to SIDEBAR locally
    channelsToMove.forEach(channel => {
      sidebarStore.removeChannelFromFolder(folderId, channel.channelId);
      sidebarStore.addChannelToFolder('SIDEBAR', channel);
    });

    // Fire and forget backend operation
    Promise.all([
      removeFolder(spaceID, folderId),
      useSidebarStore.getState().socket?.emit('folder_deleted', {
        spaceId: spaceID,
        folderId,
        channelsToMove
      })
    ]).catch(error => {
      console.error("Background operations failed:", error);
    });

  } catch (error) {
    console.error(`Failed to delete folder: ${error.message}`);
  }
};

const createRoom = (createRoomCallback) => {
  createRoomCallback();
};

const deleteRoom = async (folderId = 'SIDEBAR', channelId) => {
  const { spaceID } = useAuthStore.getState();
  const sidebarStore = useSidebarStore.getState();
  const socket = useSidebarStore.getState().socket;

  try {
    // Update local state immediately
    sidebarStore.removeChannelFromFolder(folderId, channelId);

    // Find another channel to switch to
    const allChannels = Object.values(sidebarStore.channelDetails)
      .filter(channel => channel.channel_id !== channelId);
    
    const randomChannel = allChannels[0];

    if (randomChannel) {
      sidebarStore.joinRoom(randomChannel.channel_id, 'channel', null, false);
    }

    // Fire and forget backend operations
    Promise.all([
      deleteChannel(spaceID, folderId, channelId),
      socket?.emit("room_deleted", {
        spaceId: spaceID,
        channelId,
        folderId,
        randomChannelId: randomChannel?.channel_id
      })
    ]).catch(error => {
      console.error("Background operations failed:", error);
    });

  } catch (error) {
    console.error(`Failed to delete channel: ${error.message}`);
  }
};

const renameRoom = () => {
  // call service function to rename and change jsx instantly
};

const renameFolder = () => {
  // call service function to rename and change jsx instantly
};

const muteMenu = () => {
  // call service function to rename and change jsx instantly
};

const inviteToRoom = () => {};

export const getPrimaryMenuItems = (userRole, location, createRoomCallback) => {
  if (userRole === 'admin') {
    if (location.type === 'folder') {
      return [
        {
          label: 'Create new channel',
          action: () => createRoom(createRoomCallback),
        },
        {
          label: 'Mute all notifications',
          action: muteMenu,
        },
        {
          label: 'Rename folder',
          action: renameFolder,
        },
        {
          label: 'Delete folder',
          action: () => deleteFolder(location.id),
          className: 'deleteAction'
        },
      ];
    } else if (location.type === 'room') {
      return [
        {
          label: 'Rename Channel',
          action: renameRoom,
        },
        {
          label: 'Mute Channel',
          action: muteMenu,
        },
        {
          label: 'Invite to Channel',
          action: inviteToRoom,
        },
        {
          label: 'Delete Channel',
          action: () => deleteRoom(location.folderId, location.id),
          className: 'deleteAction'
        },
      ];
    } else {
      return [];
    }
  } else if (userRole === 'member') {
    // TODO: seperate these later
    if (location.type === 'folder' || location.type === 'room') {
      return [
        {
          label: 'Mute notifications',
          action: muteMenu,
        },
      ];
    }
  }
  return [];
};