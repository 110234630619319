import io from 'socket.io-client';

let socket;

export const getSocket = () => {
  if (!socket) {
    let baseURL;
    if (process.env.NODE_ENV === 'production') {
      try {
        baseURL = window.location.origin;
      } catch (error) {
        console.warn('Failed to get window.location.origin, using fallback URL');
        baseURL = 'https://mention.so';
      }
    } else {
      baseURL = 'http://localhost:8080';
    }

    socket = io(baseURL, {
      transports: ['websocket', 'polling'],
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      path: '/socket.io',
      withCredentials: true,
      forceNew: true,
      secure: process.env.NODE_ENV === 'production',
      rejectUnauthorized: false,
      transportOptions: {
        polling: {
          extraHeaders: {
            'X-Forwarded-Proto': 'https'
          }
        }
      }
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      if (socket.io.opts.transports[0] === 'websocket') {
        console.log('Falling back to polling transport');
        socket.io.opts.transports = ['polling', 'websocket'];
      }
    });
  }
  return socket;
};