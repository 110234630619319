import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../store/authStore';
import { updateUserSpaceDetails, updateOnboardingStatus } from '../services/onboardingService';
import ImageUpload from './ImageUploads/ImageUpload';
import { createDefaultAvatar, compressImage, getAvatarColor } from '../utils/imageUtils';

import styles from '../css/SpaceDetailsStep.module.css';

const UserDetailsStep = () => {
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const fileInputRef = useRef(null);

  const { userID, spaceID, userRole, userEmail, setOnboardingStatus } = useAuthStore();

  const navigate = useNavigate();

  useEffect(() => {
    setIsFormValid(!!displayName);
  }, [displayName]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setUserImage(null);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsImageLoading(true);
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImage(reader.result);
        setIsImageLoading(false);
      };
      reader.readAsDataURL(compressedFile);
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid) return;
    setIsLoading(true);
  
    try {
      const targetStatus = userRole === 'admin' ? 'create/invites' : 'completed';
      const targetRoute = userRole === 'admin' ? '/create/invites' : `/client/${spaceID}`;
  
      let imageToSend = userImage;
      if (!userImage && displayName) {
        const previewColor = getAvatarColor(displayName);
        imageToSend = createDefaultAvatar(displayName, previewColor);
      }
  
      await Promise.all([
        updateUserSpaceDetails(userID, spaceID, displayName, bio, imageToSend, userEmail),
        updateOnboardingStatus(userID, spaceID, targetStatus)
      ]);
  
      setOnboardingStatus(targetStatus);
      navigate(targetRoute, { replace: true });
  
    } catch (error) {
      console.error('Error submitting user details:', error);
      navigate('/create', { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Edit Your Profile</h2>
      
      <div className={styles.spaceInfo}>
      
      <p className={styles.label}>User Image</p>
        <ImageUpload
          image={userImage}
          name={displayName}
          isLoading={isImageLoading}
          onImageClick={handleImageClick}
          onRemoveImage={handleRemoveImage}
          size="100"
        />

        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />

        <p className={styles.label}>Display Name</p>
        <input
          type="text"
          className={styles.spaceNameInput}
          placeholder="Enter your display name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          maxLength={50}
        />
        
        <p className={styles.label}>User Bio</p>
        <textarea
          className={styles.spaceDescriptionInput}
          placeholder="Enter your bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          maxLength={250}
        />
      </div>
      <button
        className={`${styles.secondary_button} ${isLoading ? styles.loading : ''}`}
        onClick={handleSubmit}
        disabled={!isFormValid || isLoading}
      >
        {isLoading ? 'Submitting...' : 'Next'}
      </button>
    </div>
  );
};

export { UserDetailsStep };