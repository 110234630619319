import { create } from 'zustand';
import { getUserSpaceDetails } from '../features/onboarding/services/onboardingService';

const useUserDetailsStore = create((set, get) => ({
  userDetails: new Map(),
  userStatuses: new Map(),
  
  fetchUserDetails: async (userID, spaceId = null) => {
    const details = get().userDetails.get(userID);

    // REPLACE WITH REDIS LATER
    if (details) {
      const urlCreationTime = details._urlGeneratedAt;
      const isUrlExpired = !urlCreationTime || (Date.now() - urlCreationTime) > 50 * 60 * 1000;
      
      if (!isUrlExpired) {
        return details;
      }
    }

    try {
      const fetchedDetails = await getUserSpaceDetails(userID, spaceId);

      if (!fetchedDetails) {
        console.error('Fetched details are null or undefined');
        return null;
      }

      fetchedDetails._urlGeneratedAt = Date.now();
  
      set(state => ({
        userDetails: new Map(state.userDetails).set(userID, fetchedDetails)
      }));
      
      return fetchedDetails;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  },

  getBasicUserDetails: (userID) => {
    const details = get().userDetails.get(userID);

    if (!details) return null;
  
    const userSpaceDetails = details.userSpaceDetails;

    return {
      userID: userID,
      displayName: userSpaceDetails.user_display_name || 'Unknown User',
      imageUrl: userSpaceDetails.user_image_url || 'default-avatar.png'
    };
  },  

  updateUserStatus: (userID, status) => {
    set(state => ({
      userStatuses: new Map(state.userStatuses).set(userID, status)
    }));
  },

  getUserStatus: (userID) => get().userStatuses.get(userID) || 'away',

  updateUserDetails: (userID, updatedDetails) => {
    set(state => {
      const userDetails = state.userDetails.get(userID);
      if (userDetails) {
        const updated = {
          ...userDetails,
          userSpaceDetails: {
            ...userDetails.userSpaceDetails,
            ...updatedDetails
          }
        };
        const newMap = new Map(state.userDetails);
        newMap.set(userID, updated);
        return { userDetails: newMap };
      }
      return state;
    });
  }
}));

export default useUserDetailsStore;