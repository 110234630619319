const createFolder = async (spaceId, folderData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceId}/create-folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(folderData)
    });

    if (!response.ok) {
      throw new Error("Failed to create folder");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating folder:", error);
    return null;
  }
}


const getFolders = async (spaceId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceId}/folders`);

    if (!response.ok) {
      throw new Error("Failed to fetch folders");
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching folders:", error);
    return [];
  }
};

const removeFolder = async (spaceId, folderId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceId}/remove-folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ folderId })
    });

    if (!response.ok) {
      throw new Error("Failed to remove folder");
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error removing folder:", error);
    return null;
  }
}; 

/**
 * Change the order of folders in a specific space.
 *
 * @param {string} spaceId - The ID of the space.
 * @param {Array} folderOrders - An array of folder IDs in the new order.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 */
const changeFolderOrder = async (spaceId, folderOrders) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceId}/update-folder-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ folderOrders })
    });

    if (!response.ok) {
      throw new Error("Failed to update folder order");
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating folder order:", error);
    return null;
  }
};

const getSpaceDetails = async (spaceId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceId}/get-space-details`);

    const data = await response.json();

    if (!response.ok) {
      throw new Error('Failed to fetch space details');
    }

    return data;
  } catch (error) {
    console.error('Error fetching space details:', error);
    return null;
  }
};

const renameRoomOrFolder = async (spaceId, roomId, folderId, newName) => {
  return fetch(`${process.env.REACT_APP_API_URL}rename`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ spaceId, roomId, folderId, newName }),
  }).then(response => response.json());
};

const getLastActiveRoom = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-last-active-room?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch last active room");
    }

    return data.lastActiveRoom;
  } catch (error) {
    console.error("Error fetching last active room:", error);
    throw error;
  }
};

const updateLastActiveRoom = async (userID, spaceID, roomID, roomType) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/update-last-active-room`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, spaceID, roomID, roomType }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update last active room");
    }

    return true;
  } catch (error) {
    console.error("Error updating last active room:", error);
    throw error;
  }
};

export { renameRoomOrFolder, createFolder, getFolders, removeFolder, changeFolderOrder, getSpaceDetails, getLastActiveRoom, updateLastActiveRoom};