import { openDB } from 'idb';

async function initializeKeyStore() {
  const db = await openDB('E2EEKeyStore', 2, {
    upgrade(db, oldVersion) {
      if (oldVersion < 2) {
        // Create new store with composite key
        const store = db.createObjectStore('workspace_keys', {
          keyPath: ['workspace_id', 'user_id', 'key_type']
        });
        store.createIndex('by_workspace', 'workspace_id');
        store.createIndex('by_user', 'user_id');
      }
    },
  });
  
  return {
    async setWorkspaceKey(userId, workspaceId, keyType, value) {
      await db.put('workspace_keys', {
        workspace_id: workspaceId,
        user_id: userId,
        key_type: keyType,
        value: value,
        created_at: new Date().toISOString()
      });
    },
    
    async getWorkspaceKey(userId, workspaceId, keyType) {
      return await db.get('workspace_keys', [workspaceId, userId, keyType]);
    },
  };
}

export { initializeKeyStore };