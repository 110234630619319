import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { fetchUserWorkspaces } from '../services/WorkspaceService';
import { updateLastActiveSpace } from '../features/onboarding/services/onboardingService';
import styles from './Onboarding.module.css';

const Onboarding = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [loading, setLoading] = useState(true);

  const { userEmail, userID, establishUserSpace, setOnboardingStatus } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const loadWorkspaces = async () => {
      try {
        const spaces = await fetchUserWorkspaces(userEmail);
        setWorkspaces(spaces);
      } catch (error) {
        console.error("Error loading workspaces:", error);
      } finally {
        setLoading(false);
      }
    };

    loadWorkspaces();
  }, [userEmail]);

  const handleEmailChange = () => {
    navigate('/logout', {replace: true});
  };

  const handleCreateWorkspace = async () => {
    try { 
      await updateLastActiveSpace(userEmail, '');
      establishUserSpace('', 'admin');
      setOnboardingStatus('create/space');

      navigate('/create/space', { replace: true });
    } catch (error) {
      console.error("Error creating workspace:", error);
    }
  };

  const handleWorkspaceClick = async (spaceId) => {
    try {
      await updateLastActiveSpace(userEmail, spaceId);
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}space/initialize-space?userID=${userID}&spaceID=${spaceId}`,
        { credentials: 'include' }
      );
      
      if (!response.ok) {
        throw new Error('Failed to initialize space');
      }
      
      const { userRole, onboardingStatus } = await response.json();
      establishUserSpace(spaceId, userRole);
      setOnboardingStatus(onboardingStatus);
      
      if (onboardingStatus === 'completed') {
        navigate(`/client/${spaceId}`);
      } else {
        navigate(onboardingStatus);
      }
    } catch (error) {
      console.error("Error initializing workspace:", error);
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.workspaceSection}>
        <h2>Manage Your Existing Workspaces </h2>
        <div className={styles.workspaceGrid}>
          {workspaces.map((workspace) => (
            <div
              key={workspace.spaceId}
              className={styles.workspaceCard}
              onClick={() => handleWorkspaceClick(workspace.spaceId)}
            >
              <div className={styles.workspaceImageContainer}>
                <div className={styles.memberCountTag}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5M4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12m0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7m7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44M15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35"/>
                  </svg>
                  <span>{workspace.memberCount}</span>
                </div>
                <img
                  src={workspace.imageUrl || '/default-workspace.png'}
                  alt={workspace.name}
                  className={styles.workspaceImage}
                />
              </div>
              <div className={styles.workspaceInfo}>
                <h3 className={styles.workspaceName}>{workspace.name}</h3>
                <p className={styles.workspaceDescription}>
                  {workspace.description}
                </p>
              </div>
            </div>
          ))}
          <div
            className={styles.createSpaceCard}
            onClick={handleCreateWorkspace}
          >
            <span className={styles.createIcon}>+</span>
            <span className={styles.createText}>Create a New Space</span>
          </div>
        </div>
      </div>

      <div className={styles.searchContainer}>
        <div className={styles.searchPrompt}>
          <span>Not seeing your workspace?</span>
        </div>
        <button className={styles.emailButton} onClick={handleEmailChange}>
          Try a Different Email
        </button>
      </div>
    </div>
  );
};

export { Onboarding };