import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../store/authStore';
import { sendInvites, saveJoinCode, updateOnboardingStatus } from '../services/onboardingService';
import { getWorkspaceSharedKey } from '../../../services/indexedDBService';
import InviteEmailBox from '../../../components/inviteBox/InviteEmailBox';

import styles from '../css/InviteUsersStep.module.css';
const { nanoid } = require('nanoid');

const InviteUsersStep = () => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userID, spaceID, setOnboardingStatus } = useAuthStore();
  const navigate = useNavigate();

  const generateUniqueInviteLink = async () => {
    const baseDomain = process.env.NODE_ENV === 'production'
      ? 'https://mention.so'
      : 'http://localhost:3000';

    let uniqueCode = nanoid(15); 

    await saveJoinCode(spaceID, userID, uniqueCode);
    return `${baseDomain}/invite/${uniqueCode}`;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const link = await generateUniqueInviteLink();
      const sharedKey = await getWorkspaceSharedKey(userID, spaceID);
      const exportedKey = await window.crypto.subtle.exportKey("jwk", sharedKey);
      
      const workspaceKeys = { workspace_id: spaceID, workspace_shared_key: exportedKey };
      await sendInvites(emails, link, workspaceKeys, spaceID);

      setOnboardingStatus('completed');
      await updateOnboardingStatus(userID, spaceID, 'completed');
      navigate(`/client/${spaceID}`, { replace: true });
    } catch (error) {
      console.error('Error sending invites:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Send Invites</h2>
      <p className={styles.label}>Enter emails to invite people to your space</p>
      
      <div className={styles.inviteEmailBoxWrapper}>
        <InviteEmailBox
          emails={emails}
          setEmails={setEmails}
          onSendInvites={handleSubmit}
          isLoading={isLoading}
        />
      </div>
      
      <button onClick={handleSubmit} className={styles.skipButton}>Skip</button>
    </div>
  );
};

export { InviteUsersStep };