import React from 'react';
import styles from './KeyGenerationLoadingView.module.css';
import { FaSpinner } from 'react-icons/fa';

const KeyGenerationLoadingView = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Generating Keys</h2>
      <div className={styles.spinnerContainer}>
        <FaSpinner className={styles.spinner} />
      </div>
      <p className={styles.message}>Please wait while we securely generate your encryption keys...</p>
    </div>
  );
};

export default KeyGenerationLoadingView;