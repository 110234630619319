import React, { useState } from 'react';
import styles from './BillingView.module.css';
import Checkout from './Checkout/Checkout';

const BillingView = () => {
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const plans = [
    {
      name: 'Starter',
      price: 0,
      features: [
        'Up to 10 guests',
        'Automatic key rotations',
        'Unlimited message history',
        'Single administrator',
        'Multi-factor authentication',
        '10GB File storage limits'
      ]
    },
    {
      name: 'Professional',
      price: {
        monthly: 35,
        annually: 30
      },
      features: [
        'Up to 100 guests',
        '24hr customer support',
        'Data export options',
        'Manual & automatic key rotations',
        '50GB File storage limits'
      ]
    },
    {
      name: 'Enterprise',
      price: {
        monthly: 14,
        annually: 15
      },
      features: [
        'Unlimited guests',
        'Single sign-on',
        'Remote key backup',
        'Remote data storage',
        'Bring your own keys',
        'Grid space system',
      ]
    }
  ];

  const handleUpgrade = (planName) => {
    setSelectedPlan(planName);
    setCheckoutOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>Plans & Billing</h1>
          <p className={styles.caption}>Scale your workspace with the right plan.</p>
        </div>
      </div>

      <div className={styles.plansGrid}>
        {plans.map((plan) => (
          <div key={plan.name} className={styles.planCard}>
            <div className={styles.planHeader}>
                <h2>{plan.name}</h2>
              <div className={styles.pricing}>

                  {plan.name === 'Starter' ? (
                    <div className={styles.annualPrice}>
                      Free forever
                  </div>
                ) : plan.name === 'Enterprise' ? (
                  <div className={styles.annualPrice}>
                    Contact us for enterprise pricing
                  </div>
                ) : (
                  <>
                    <div className={styles.annualPrice}>
                      ${plan.price.annually} per member / month billed annually
                    </div>
                    <div className={styles.priceContainer}>
                      <span className={styles.price}>
                        ${plan.price.monthly}
                      </span>
                      <span className={styles.billingPeriod}>billed monthly</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            
            <ul className={styles.features}>
              {plan.features.map((feature) => (
                <li key={feature}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.9rem" height="0.9rem" viewBox="0 0 32 32">
                    <rect width="32" height="32" fill="none" />
                    <path fill="currentColor" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415z" />
                    <path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                </svg>
                {feature}
              </li>
            ))}
          </ul>

            <button 
              className={styles.upgradeButton}
              onClick={() => handleUpgrade(plan.name)}
            >
              {plan.name === 'Starter' ? 'Current plan' : plan.name === 'Enterprise' ? 'Contact us' : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 32 32">
                    <path fill="currentColor" d="M21 24H11a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2m0 4H11v-2h10zm7.707-13.707l-12-12a1 1 0 0 0-1.414 0l-12 12A1 1 0 0 0 4 16h5v4a2 2 0 0 0 2 2h10a2.003 2.003 0 0 0 2-2v-4h5a1 1 0 0 0 .707-1.707M21 14v6H11v-6H6.414L16 4.414L25.586 14z" />
                  </svg>
                  Upgrade
                </>
              )}
            </button>
          </div>
        ))}
      </div>

      {/* <div className={styles.titleContainer}>
        <h1 className={styles.title}>Billing history</h1>
        <p className={styles.caption}>Manage past invoices and previous billing history.</p>
      </div> */}

      <div className={styles.titleContainer}>
        <h1 className={styles.title}>FAQ</h1>
        <a 
          href="https://mention.so/privacy" 
          target="_blank" 
          rel="noopener noreferrer" 
          className={styles.faqLink}
        >
          Plans, billing, and more.
        </a>
      </div>

      <button className={`${styles.upgradeButton} ${styles.supportButton}`}>Message support</button> 
      
      {checkoutOpen && (
        <Checkout 
          isOpen={checkoutOpen}
          onClose={() => setCheckoutOpen(false)}
          selectedPlan={selectedPlan}
          // interval={selectedInterval}
        />
      )}
    </div>
  );
};

export default BillingView;