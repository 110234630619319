import React, { useState, useEffect } from 'react';
import styles from './MessageAttachments.module.css';

const MessageAttachments = ({ attachments }) => {
  const [signedUrls, setSignedUrls] = useState({});

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (!attachments || attachments.length === 0) return;
      
      const urls = {};
      for (const attachment of attachments) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}attachments/signed-url?key=${encodeURIComponent(attachment.key)}`
          );
          if (response.ok) {
            const data = await response.json();
            urls[attachment.key] = data.signedUrl;
          }
        } catch (error) {
          console.error('Error fetching signed URL:', error);
        }
      }
      setSignedUrls(urls);
    };

    fetchSignedUrls();
  }, [attachments]);

  if (!attachments || attachments.length === 0) return null;

  return (
    <div className={styles.attachmentsContainer}>
      {attachments.map((attachment, index) => {
        const signedUrl = signedUrls[attachment.key];
        
        if (attachment.type.startsWith('image/')) {
          return (
            <div key={index} className={styles.imageAttachment}>
              <img 
                src={signedUrl || '/placeholder-image.png'}
                alt={attachment.originalName || attachment.name}
                onClick={() => signedUrl && window.open(signedUrl, '_blank')}
              />
            </div>
          );
        }
        return (
          <div key={index} className={styles.fileAttachment}>
            <a href={signedUrl} target="_blank" rel="noopener noreferrer">
              {attachment.originalName || attachment.name}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default MessageAttachments;