import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useSidebarViewModel from "./SidebarViewModel";
import SidebarContextMenuView from "../contextMenu/SidebarContextMenuView";
import CreateFolderModalView from "../modals/folderCreation/CreateFolderModalView";
import CreateRoomModalView from "../modals/roomCreation/CreateRoomModalView";
import SettingsModal from "../../../settings/components/SettingsModal";
import AddNewContextMenuView from "../contextMenu/AddNewContextMenuView";
import UserDetailsModal from '../../../user/UserDetailsModal';
import SpaceDropdownView from '../contextMenu/spaceDropdown/SpaceDropdownView';
import useSettingsStore from '../../../../store/settingsStore';

import styles from "./Sidebar.module.css";

const SidebarView = () => {
  const navigate = useNavigate();

  const { 
    userRole,
    folders,
    channelDetails,
    toggleFolder,
    collapsedFolders,
    contextMenu,
    fadingOut,
    joinRoom,
    currentRoom,
    folderContents,
    isCreateFolderModalVisible,
    isCreateRoomModalVisible,
    closeContextMenu,
    handleContextMenu,
    setCreateFolderModalVisible, 
    setCreateRoomModalVisible,
    dmConversations,
    groupChats,
    userDetails,
    spaceDetails,
    toggleDMCreationFlow,
    unreadRooms,
    isOnline,
    fetchUserDetails,
    addNewContextMenu,
    setAddNewContextMenu,
    userID,
    showSpaceDropdown,
    setShowSpaceDropdown,
    workspaces,
    handleSpaceSwitch,
  } = useSidebarViewModel();

  const [menuLocation, setMenuLocation] = useState("");
  const [folderId, setFolderId] = useState('SIDEBAR');
  const setIsSettingsModalOpen = useSettingsStore(state => state.setIsSettingsModalOpen);
  const isSettingsModalOpen = useSettingsStore(state => state.isSettingsModalOpen);
  const [showUserModal, setShowUserModal] = useState(false);

  const handleFolderContextMenu = (event, currentFolderId) => {
    handleContextMenu(event, currentFolderId);
    setMenuLocation({ type: "folder", id: currentFolderId });
    setFolderId(currentFolderId);
  };

  const handleRoomContextMenu = (event, roomId, folderId) => {
    event.preventDefault(); 
    event.stopPropagation();
    handleContextMenu(event, folderId, roomId);
    setMenuLocation({ type: "room", id: roomId, folderId });
  };

  const RoomItem = ({ room, folderId, type }) => {
    const [displayName, setDisplayName] = useState("");
    const [imageUrl, setImageUrl] = useState("default-avatar.png");
    
    const roomId = room.channel_id || room.dm_id || room.group_id;
    const isUnread = unreadRooms.has(roomId);
    const isActive = currentRoom.room_id === roomId;

    useEffect(() => {
      const loadUserDetails = async () => {
        if (type === "dm") {
          const details = await fetchUserDetails(room.receiver_id, room.space_id);
          if (details?.userSpaceDetails) {
            setDisplayName(details.userSpaceDetails.user_display_name || "Unknown User");
            setImageUrl(details.userSpaceDetails.user_image_url || "default-avatar.png");
          }
        } else if (type === "group_chat" && room.member_ids?.length > 0) {
          const memberDetails = await Promise.all(
            room.member_ids.map(id => fetchUserDetails(id, room.space_id))
          );
          
          const names = memberDetails
            .filter(detail => detail?.userSpaceDetails)
            .map(detail => detail.userSpaceDetails.user_display_name)
            .filter(name => name);

          if (memberDetails[0]?.userSpaceDetails?.user_image_url) {
            setImageUrl(memberDetails[0].userSpaceDetails.user_image_url);
          }

          setDisplayName(names.join(', '));
        }
      };

      loadUserDetails();
    }, [room, type]);

    return (
      <li
        className={`${styles.roomItem} ${isActive ? styles.activeRoom : ''} ${isUnread ? styles.unreadRoom : ''}`}
        onClick={() => joinRoom(roomId, type, room.receiver_id)}
        onContextMenu={(event) => handleRoomContextMenu(event, roomId, folderId)}
      >
        <span className={styles.channelToggle}>
          {type === "channel" ? (
            <span className={`${styles.roomSymbol} ${isUnread ? styles.unreadRoom : ''}`}>
              <i className="fa-solid fa-hashtag"></i>
            </span>
          ) : (
            <img 
              src={imageUrl} 
              alt="avatar"
              className={styles.roomAvatar}
            />
          )}
          <span className={`${type === "channel" ? styles.channelName : styles.chatName} ${isUnread ? styles.unreadRoom : ''}`}>
            {type === "channel" ? 
              (channelDetails[room.channel_id]?.channel_name || room.channel_id) :
              displayName
            }
          </span>
        </span>
      </li>
    );
  };
  
  const FolderItem = ({ folder }) => {
    const isFolderExpanded = !collapsedFolders.has(folder.folder_id);
    
    return (
      <li
        className={styles.sidebar__folder_item}
        onContextMenu={(event) => handleFolderContextMenu(event, folder.folder_id)}
      >
        <div className={styles.sidebar__folder_header}>
          <div
            className={styles.sidebar__folder_header_container}
            onClick={() => toggleFolder(folder.folder_id)}
          >
            <span className={styles.sidebar__folder_toggle}>
              {isFolderExpanded ? (
                <i className="fa-solid fa-caret-down"></i>
              ) : (
                <i className="fa-solid fa-caret-right"></i>
              )}
              {folder.folder_name}
            </span>
          </div>
          <span
            className={styles.sidebar__folder_menu}
            onClick={(event) => handleFolderContextMenu(event, folder.folder_id)}
          >
            <i className="fa-solid fa-ellipsis"></i>
          </span>
        </div>
        {isFolderExpanded && folderContents[folder.folder_id] && (
          <ul className={styles.sidebar__channel_list}>
            {folderContents[folder.folder_id].map(room => (
              <RoomItem key={room.channel_id} room={room} folderId={folder.folder_id} type="channel"/>
            ))}
          </ul>
        )}
      </li>
    );
  };

  const handleAddNewClick = (event) => {
    event.preventDefault();
    setAddNewContextMenu({ 
      mouseX: event.clientX, 
      mouseY: event.clientY 
    });
  };

  const handleProfileClick = () => {
    setShowUserModal(true);
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.space__info_container}>
        <div 
          className={styles.sidebar__space_info}
          onClick={() => setShowSpaceDropdown(!showSpaceDropdown)}
        >
          <img
            src={spaceDetails.space_image_url}
            alt="Space"
            className={styles.sidebar__space_image}
          />
          <p className={styles.sidebar__space_name}>{spaceDetails.space_name}</p>
          <div className={styles.sidebar__angle_down_container}>
            <i className={`fa-solid fa-angle-down ${showSpaceDropdown ? styles.rotated : ''}`}></i>
          </div>
          <div className={styles.sidebar__pen_to_square_background} onClick={toggleDMCreationFlow}>
            <i className="fa-regular fa-pen-to-square"></i>
          </div>
        </div>

        {showSpaceDropdown && (
          <SpaceDropdownView
            workspaces={workspaces}
            handleSpaceSwitch={handleSpaceSwitch}
            onClose={() => setShowSpaceDropdown(false)}
          />
        )}
        
        <ul className={styles.sidebar__navigation}>
          <li className={styles.sidebar__nav_item}>
            <span role="img" aria-label="Home">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" className={styles.home_svg} viewBox="0 0 16 16">
                <path fill="currentColor" d="M6.906.664a1.75 1.75 0 0 1 2.187 0l5.25 4.2c.415.332.657.835.657 1.367v7.019A1.75 1.75 0 0 1 13.25 15h-3.5a.75.75 0 0 1-.75-.75V9H7v5.25a.75.75 0 0 1-.75.75h-3.5A1.75 1.75 0 0 1 1 13.25V6.23c0-.531.242-1.034.657-1.366l5.25-4.2Zm1.25 1.171a.25.25 0 0 0-.312 0l-5.25 4.2a.25.25 0 0 0-.094.196v7.019c0 .138.112.25.25.25H5.5V8.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v5.25h2.75a.25.25 0 0 0 .25-.25V6.23a.25.25 0 0 0-.094-.195Z"/>
              </svg> Home
            </span>
          </li>
          <li className={styles.sidebar__nav_item}>
            <span role="img" aria-label="search">
              <i className="fa-solid fa-magnifying-glass"></i> Search
            </span>
          </li>
        </ul> 
 
      </div>

      <div className={styles.sidebarWrapper}>
        <ul className={styles.sidebar__independent_rooms}>
          {<p className={styles.sidebar__rooms_header}>Workspace</p>}
          {<div className={styles.sidebar__divider}></div>}
          {folderContents['SIDEBAR']?.map(room => (
            <RoomItem key={room.channel_id} room={room} folderId="SIDEBAR" type="channel" />
          ))}
        </ul>

        {userRole === 'admin' && (
          <button 
            className={styles.addNewButton}
            onClick={handleAddNewClick}
          >
            <i className="fa-solid fa-plus"></i> Add New
          </button>
        )}

        <ul className={styles.sidebar__folder_list}>
          {folders
            .filter(folder => folder.folder_id !== 'SIDEBAR')
            .map(folder => (
              <FolderItem key={folder.folder_id} folder={folder} />
            ))}
        </ul>

        <ul className={styles.sidebar__personal_rooms}>
          <li className={styles.sidebar__section_header}>Direct Messages</li>
          {<div className={styles.sidebar__divider_personal}></div>}
          {dmConversations.map(dm => (
            <RoomItem key={dm.dm_id} room={dm} folderId="SIDEBAR" type="dm" className={styles.test} />
          ))}
          {groupChats.map(groupChat => (
            <RoomItem key={groupChat.group_id} room={groupChat} folderId="SIDEBAR" type="group_chat" />
          ))}
        </ul>

        <button 
          className={styles.newChatButton}
          onClick={toggleDMCreationFlow}
        >
          <i className="fa-solid fa-plus"></i> Add New
        </button>
      </div>

      <div 
        className={styles.sidebar__profile_info}
      >
        <div 
          className={styles.profileSection}
          onClick={handleProfileClick}
        >
          <img
            src={userDetails?.userSpaceDetails?.user_image_url || '/default-avatar.png'}
            alt="Profile"
          />
          <div>
            <p>{userDetails?.userSpaceDetails?.user_display_name}</p>
            <div className={styles.statusContainer}>
              <span>{isOnline ? 'Active' : 'Away'}</span>
              <div className={`${styles.statusIndicator} ${isOnline ? styles.online : styles.away}`} />
            </div>
          </div>
        </div>
        <div 
          className={styles.settingsIcon}
          onClick={() => setIsSettingsModalOpen(true)}
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29a.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19a1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22a.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5a1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29a.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19a1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22a.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5a1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22m-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14a2.36 2.36 0 0 1 2.59.47l.54.54l.88-.88l-.54-.55a2.34 2.34 0 0 1-.48-2.56a2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46a2.36 2.36 0 0 1 .47-2.59l.54-.54l-.88-.88l-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14a2.36 2.36 0 0 1-2.59-.47l-.54-.54l-.88.88l.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46a2.36 2.36 0 0 1-.47 2.59l-.54.54l.88.88l.55-.54a2.39 2.39 0 0 1 4 1.67Z" />
          <path fill="currentColor" d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5m0-5a1.5 1.5 0 1 0 1.5 1.5a1.5 1.5 0 0 0-1.5-1.5" />
        </svg>
        </div>
      </div>

      {showUserModal && (
        <UserDetailsModal 
          userID={userID}
          onClose={() => setShowUserModal(false)}
        />
      )}

      {contextMenu && (
        <SidebarContextMenuView
          mouseX={contextMenu.mouseX}
          mouseY={contextMenu.mouseY}
          onClose={closeContextMenu}
          fadingOut={fadingOut}
          userRole={userRole}
          location={menuLocation}
          createRoom={() => setCreateRoomModalVisible(true, folderId)}
          createFolder={() => setCreateFolderModalVisible(true)}
          className={styles.sidebar__context_menu}
        />
      )}

      <CreateFolderModalView
        isVisible={isCreateFolderModalVisible}
        onClose={() => setCreateFolderModalVisible(false)}
      />

      <CreateRoomModalView
        isVisible={isCreateRoomModalVisible}
        onClose={() => setCreateRoomModalVisible(false)}
        folderId={folderId}
      />

      {isSettingsModalOpen && (
        <SettingsModal onClose={() => setIsSettingsModalOpen(false)} />
      )}

      {addNewContextMenu && (
        <AddNewContextMenuView
          mouseX={addNewContextMenu.mouseX}
          mouseY={addNewContextMenu.mouseY}
          onClose={() => setAddNewContextMenu(null)}
          fadingOut={fadingOut}
          createRoom={() => {
            setFolderId('SIDEBAR');
            setCreateRoomModalVisible(true);
          }}
          createFolder={() => setCreateFolderModalVisible(true)}
        />
      )}
    </div>
  );
};

export default SidebarView;