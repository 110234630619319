import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import styles from './Checkout.module.css';

const stripePromise = loadStripe('pk_test_51QPr67GKpk2qFBnGDulUrBbzP9MyfGpKKUfD5gP8xOuGwzo12YF1sHPrk1ydlYkfiEcpnn1bFNulIPYrLfgSlZKD005U5LdMmN');

const CheckoutForm = ({ selectedPlan, interval, onClose }) => {
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        
        <div className={styles.checkoutContainer}>
          <div className={styles.checkoutDetails}>
            <h2>Upgrade to {selectedPlan}</h2>
            <div className={styles.planSummary}>
              <div className={styles.priceInfo}>
                <span className={styles.price}>$35</span>
                <span className={styles.interval}>per user / {interval}</span>
              </div>
              <p className={styles.description}>
                Billed {interval === 'month' ? 'monthly' : 'annually'}
              </p>
            </div>

            <div className={styles.formSection}>
              <h3>Billing Information</h3>
              <AddressElement 
                options={{
                  mode: 'billing',
                  defaultValues: {
                    address: {
                      country: 'US',
                    },
                  },
                }}
              />
            </div>

            <div className={styles.formSection}>
              <h3>Payment Method</h3>
              <PaymentElement />
            </div>

            <button type="submit" className={styles.submitButton}>
              Upgrade Now
            </button>
          </div>

          <div className={styles.orderSummary}>
            <h3>Order Summary</h3>
            <div className={styles.summaryItems}>
              <div className={styles.summaryItem}>
                <span>Pro Plan</span>
                <span>$35/user/{interval}</span>
              </div>
              <div className={styles.summaryItem}>
                <span>Billable Users</span>
                <span>5</span>
              </div>
            </div>
            <div className={styles.total}>
              <span>Total</span>
              <span>$175/{interval}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkout = ({ isOpen, onClose, selectedPlan, interval }) => {
  if (!isOpen) return null;

  const options = {
    mode: 'subscription',
    amount: 1099,
    currency: 'usd',
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm 
        selectedPlan={selectedPlan}
        interval={interval}
        onClose={onClose}
      />
    </Elements>
  );
};

export default Checkout;
