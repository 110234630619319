import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SpaceDropdown.module.css';
import useSettingsStore from '../../../../../store/settingsStore';

const SpaceDropdownView = ({ workspaces, handleSpaceSwitch, onClose }) => {
  const navigate = useNavigate();
  const setIsSettingsModalOpen = useSettingsStore(state => state.setIsSettingsModalOpen);
  const setSelectedSection = useSettingsStore(state => state.setSelectedSection);

  const handleSettingsClick = (location) => {
    setSelectedSection(location);
    setIsSettingsModalOpen(true);
    onClose();
  };

  return (
    <div className={styles.workspace_dropdown} onClick={e => e.stopPropagation()}>
      <div 
        className={`${styles.action_item} ${styles.upgrade_item}`}
        onClick={() => handleSettingsClick('billing')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 32 32">
          <path fill="currentColor" d="M21 24H11a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2m0 4H11v-2h10zm7.707-13.707l-12-12a1 1 0 0 0-1.414 0l-12 12A1 1 0 0 0 4 16h5v4a2 2 0 0 0 2 2h10a2.003 2.003 0 0 0 2-2v-4h5a1 1 0 0 0 .707-1.707M21 14v6H11v-6H6.414L16 4.414L25.586 14z" />
        </svg>
        Upgrade
      </div>

      <div className={styles.divider} />
      
      <div 
        className={styles.action_item} 
        onClick={() => handleSettingsClick('member-portal')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5M4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12m0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7m7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44M15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35" />
        </svg>
        Invite People
      </div>
      
      <div className={styles.divider} />

      <div className={styles.workspaces_list}>
        {workspaces.map((workspace) => (
          <div
            key={workspace.spaceId}
            className={styles.workspace_item}
            onClick={() => handleSpaceSwitch(workspace.spaceId)}
          >
            <img
              src={workspace.imageUrl}
              alt={workspace.name}
              className={styles.workspace_image}
            />
            <div className={styles.workspace_info}>
              <h4>{workspace.name}</h4>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.divider} />

      <div 
        className={styles.action_item}
        onClick={() => navigate('/')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 48 48">
          <rect width="48" height="48" fill="none" />
          <path 
            fill="none" 
            stroke="currentColor" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="3.5"
            d="M5.5 5.5h15.857v15.857H5.5zm19.07 7.928l10-10l10 10l-10 10zM5.5 26.643h15.857V42.5H5.5zm21.143 0H42.5V42.5H26.643z" 
          />
        </svg>
        Manage Workspaces
      </div>
    </div>
  );
};

export default SpaceDropdownView;