import React from 'react'; 

const NotFoundView = () => { 
  return (
    <div>
      <h1>404 Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
    </div>
  );
};
 
export { NotFoundView };