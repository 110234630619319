const fetchThreadMessages = async (spaceId, parentMessageId, before = new Date().toISOString(), limit = 50) => {
  try {
    const url = new URL(`${process.env.REACT_APP_API_URL}threads/${spaceId}/${parentMessageId}`);
    url.searchParams.append('before', before); 
    url.searchParams.append('limit', limit.toString());

    const response = await fetch(url.toString());

    if (!response.ok) {
      throw new Error("Failed to fetch thread messages");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching thread messages in service:", error);
    throw error;
  }
};

export {
  fetchThreadMessages
};