import { storeWorkspaceSharedKey } from './indexedDBService';

async function generateWorkspaceSharedKey() {
  return await window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256
    },
    true,
    ["encrypt", "decrypt"]
  );
}

async function registerWorkspaceSharedKey(userId, workspaceId) {
  const sharedKey = await generateWorkspaceSharedKey();
  await storeWorkspaceSharedKey(userId, workspaceId, sharedKey);
  return sharedKey;
}

export { generateWorkspaceSharedKey, registerWorkspaceSharedKey };