const startNewGroupChat = async (space_id, member_ids, group_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}start-new-group-chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ space_id, member_ids, group_id })
    });

    if (!response.ok) {
      throw new Error("Failed to start a new DM");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error starting new DM in service:", error);
    return null;
  }
};

const fetchUserGroupChats = async (space_id, user_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${space_id}/${user_id}/fetch-group-chats`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user group chats");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching user group chats in service:", error);
    return null;
  }
};

const deleteGroupChatConversation = async (group_id, user_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${group_id}/delete-group-chat`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id })
    });

    if (!response.ok) {
      throw new Error("Failed to hide group chat conversation");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error hiding group chat conversation in service:", error);
    return null;
  }
};

const fetchGroupChatMessages = async (space_id, group_id, before, limit) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${space_id}/${group_id}/fetch-group-chat-messages?before=${before}&limit=${limit}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error("Failed to fetch group chat messages");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching group chat messages in service:", error);
    return null;
  }
};

const deleteGroupChatMessage = async (group_id, message_id, created_at) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${group_id}/${message_id}/delete-group-chat-message`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ created_at })
    });

    if (!response.ok) {
      throw new Error("Failed to delete group chat message");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error deleting group chat message in service:", error);
    return null;
  }
};

export {
  startNewGroupChat,
  fetchGroupChatMessages,
  fetchUserGroupChats,
  deleteGroupChatMessage,
  deleteGroupChatConversation,
};