import { create } from 'zustand'

const useAuthStore = create((set) => ({
  isAuthenticated: false,
  userID: '',
  spaceID: '',
  userRole: '',
  userEmail: '',
  onboardingStatus: '',
  isInitialized: false,

  initializeAuth: async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}auth/check-session`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        set({ isAuthenticated: false, isInitialized: true });
        return false;
      }

      const userData = await response.json();
      
      set({
        isAuthenticated: true,
        userEmail: userData.email,
        userID: userData.userID,
        spaceID: userData.spaceID,
        userRole: userData.userRole,
        onboardingStatus: userData.onboardingStatus,
        isInitialized: true
      });

      return true;
    } catch (error) {
      console.error('Auth initialization error:', error);
      set({ isAuthenticated: false, isInitialized: true });
      return false;
    }
  },

  setOnboardingStatus: (status) => set({ onboardingStatus: status }),
  setUserID: (userID) => set({ userID }),
  establishUserSpace: (spaceID, userRole) => set({ spaceID, userRole }),

  terminateUserSession: () => {
    set({ 
      isAuthenticated: false, 
      userEmail: null, 
      userID: null,
      userRole: null, 
      spaceID: null,
      onboardingStatus: null,
    });
  }
}));

export default useAuthStore;