import { useState } from 'react';
import { createFolder } from '../../../services/spaceService';
import useSidebarStore from '../../../../../store/sidebarStore';
import useAuthStore from '../../../../../store/authStore';
import { v4 as uuidv4 } from 'uuid';

const useCreateFolderModalViewModel = () => {
  const [folderName, setFolderName] = useState('');
  const spaceId = useAuthStore(state => state.spaceID);

  const handleCreateFolder = async () => {
    const folderData = {
      folderId: uuidv4(),
      folderName,
    };
     
    // Update local state immediately
    useSidebarStore.getState().addFolder({
      folder_id: folderData.folderId,
      folder_name: folderData.folderName
    });

    // Fire and forget backend operations
    Promise.all([
      createFolder(spaceId, folderData),
      useSidebarStore.getState().socket?.emit('folder_created', {
        spaceId,
        folderData
      })
    ]).catch(error => {
      console.error("Background operations failed:", error);
      // Could add rollback logic here if needed
    });
  };

  return {
    setFolderName,
    handleCreateFolder
  };
};

export default useCreateFolderModalViewModel; 