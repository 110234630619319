import { storeWorkspaceSharedKey } from "../../../services/indexedDBService";

const validateKeyFile = async (file) => {
  try {
    const text = await file.text();
    const keys = JSON.parse(text);
    
    // Validate structure
    if (!keys.workspace_shared_key || !keys.workspace_id) {
      throw new Error('Invalid key file structure');
    }
    
    // Convert the JWK back to CryptoKey
    const cryptoKey = await window.crypto.subtle.importKey(
      "jwk",
      keys.workspace_shared_key,
      {
        name: "AES-GCM",
        length: 256
      },
      true,
      ["encrypt", "decrypt"]
    );
    
    return {
      ...keys,
      workspace_shared_key: cryptoKey
    };
  } catch (error) {
    console.error('Key file validation error:', error);
    throw new Error('Invalid key file');
  }
};

const storeWorkspaceKeys = async (userId, workspaceId, keys) => {
  if (workspaceId !== keys.workspace_id) {
    throw new Error('Workspace ID mismatch');
  }
  await storeWorkspaceSharedKey(userId, workspaceId, keys.workspace_shared_key);
};

export { validateKeyFile, storeWorkspaceKeys };