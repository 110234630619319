const directMessageService = require("./directMessageService");
const groupChatService = require("./groupChatService");
const threadService = require("./threadService");
const DMCreationFlowService = require("./DMCreationFlowService");

module.exports = {
  // DM
  startNewDM: directMessageService.startNewDM,
  getDMs: directMessageService.getDMs,
  fetchDMList: directMessageService.fetchDMList,
  deleteDMMessage: directMessageService.deleteDMMessage,
  deleteDMConversation: directMessageService.deleteDMConversation,

  // Group Chat
  startNewGroupChat: groupChatService.startNewGroupChat,
  fetchUserGroupChats: groupChatService.fetchUserGroupChats,
  deleteGroupChatConversation: groupChatService.deleteGroupChatConversation,
  fetchGroupChatMessages: groupChatService.fetchGroupChatMessages,
  deleteGroupChatMessage: groupChatService.deleteGroupChatMessage,

  // Thread
  fetchThreadMessages: threadService.fetchThreadMessages,

  // DMCreationFlowService
  fetchDMwithUser: DMCreationFlowService.fetchDMwithUser,
  fetchGroupChatsWithUsers: DMCreationFlowService.fetchGroupChatsWithUsers,
  fetchSpaceMembers: DMCreationFlowService.fetchSpaceMembers,
};