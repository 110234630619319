import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { LandingPage } from './LandingPage';
import { Onboarding } from './Onboarding';

const RootView = () => {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, initializeAuth } = useAuthStore();
  const location = useLocation();

  useEffect(() => {
    const verifySession = async () => {
      await initializeAuth();
      
      // Handle hash fragment from OAuth callback
      const hash = window.location.hash.slice(1);
      if (hash) {
        try {
          const userData = JSON.parse(atob(hash));
          window.location.hash = ''; // Clear the hash
          
          if (userData.redirectUrl) {
            window.location.href = userData.redirectUrl;
            return;
          }
        } catch (error) {
          console.error('Error parsing OAuth data:', error);
        }
      }
      
      // Handle React Router state redirect
      if (isAuthenticated && location.state?.from?.startsWith('/invite/')) {
        window.location.href = location.state.from;
        return;
      }
      
      setLoading(false);
    };
    verifySession();
  }, [initializeAuth, isAuthenticated, location.state]);

  if (loading) {
    return null;
  }

  return isAuthenticated ? <Onboarding /> : <LandingPage />;
};

export { RootView }; 