const fetchLatestMessagesForSpace = async (spaceId, userID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}activity/${spaceId}/${userID}/fetch-latest-messages`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch latest messages');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching latest messages:', error);
    throw error;
  }
};

const fetchLatestRoomActivity = async (spaceId, userID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}activity/${spaceId}/${userID}/read-status`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch room read status');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching room read status:', error);
    throw error;
  }
};

const updateLatestRoomActivity = async (spaceId, userID, roomId, roomType, timestamp) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}activity/update-read-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ spaceId, userID, roomId, roomType, timestamp }),
    });
    
    if (!response.ok) {
      throw new Error('Failed to update room read status');
    }
  } catch (error) {
    console.error('Error updating room read status:', error);
    throw error;
  }
};

export {
  fetchLatestMessagesForSpace,
  fetchLatestRoomActivity,
  updateLatestRoomActivity,
};