import React from 'react';
import styles from '../css/LoginForm.module.css';
import icon from '../../../assets/icons/Google.svg';
import { initiateGoogleLogin } from '../services/AuthService';

function LoginForm({ email, setEmail, handleLogin, error, redirectUrl }) {
  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin(email);
  };

  const handleGoogleLogin = () => {
    initiateGoogleLogin(redirectUrl);
  };

  return (
    <form onSubmit={onSubmit} className={styles.loginForm}>
      <h1 className={styles.loginForm__title}>Sign in to Mention</h1>
      <label htmlFor="email" className={styles.emailLabel}>Enter your email to login</label>
      <input type="email" className={styles.emailInput}
        value={email}
        autoComplete="email"  
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        required
        maxLength={50}
      />
      <button className = {styles.secondary_button} type="submit">Continue</button>
      <p className={styles.or}>OR</p>
      <button 
        type="button"
        className={styles.secondary_button}
        onClick={handleGoogleLogin}
      >
        <img src={icon} alt="Google Icon" className={styles.googleIcon}/> 
        Log in with Google
      </button>

      <p className={styles.terms}>By continuing, you agree to Mention's <a href="/privacy" target='_blank' className={styles.termsLink}>Terms and Conditions</a>.</p>
      {error && <p className={styles.error}>{error}</p>}
    </form>
  );
}

export { LoginForm };