import React from 'react';
import styles from './SidebarContextMenu.module.css';
import { getPrimaryMenuItems } from './SidebarContextMenuViewModel';

const SidebarContextMenuView = ({ mouseX, mouseY, onClose, fadingOut, userRole, location, createRoom, createFolder }) => {
  const handleClose = () => {
    onClose();
  };

  const primaryMenuItems = getPrimaryMenuItems(userRole, location, createRoom, createFolder);

  // Don't render anything if there are no menu items
  if (!primaryMenuItems || primaryMenuItems.length === 0) {
    return null;
  }

  return (
    <div
      className={`${styles.sidebarContextMenu} ${fadingOut ? styles.fadeOut : ''}`}
      style={{ top: mouseY, left: mouseX }}
      onClick={handleClose}
    >
      <ul>
        {primaryMenuItems.map((item, index) => (
          <li 
            key={index} 
            onClick={() => item.action(location)}
            className={item.className ? styles[item.className] : ''}
          >
            {/* LOCATION: FOLDER */}
            {item.label === 'Create new channel' && <i className={`fa-solid fa-plus ${styles.menuIcon}`}></i>}
            {item.label === 'Mute all notifications' && <i className={`fa-solid fa-bell ${styles.menuIcon}`}></i>}
            {item.label === 'Rename folder' && 
              <svg xmlns="http://www.w3.org/2000/svg" className={styles.editIcon} width="1.2em" height="1.2em" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="m5 16l-1 4l4-1L19.586 7.414a2 2 0 0 0 0-2.828l-.172-.172a2 2 0 0 0-2.828 0zM15 6l3 3m-5 11h8" />
              </svg>
            }
            {item.label === 'Delete folder' && <i className={`fa-solid fa-trash ${styles.menuIcon} ${styles.deleteIcon}`}></i>}


            {/* LOCATION: ROOM */}
            {item.label === 'Rename Channel' && 
              <svg xmlns="http://www.w3.org/2000/svg" className={styles.editIcon} width="1.2em" height="1.2em" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="m5 16l-1 4l4-1L19.586 7.414a2 2 0 0 0 0-2.828l-.172-.172a2 2 0 0 0-2.828 0zM15 6l3 3m-5 11h8" />
              </svg>
            }            
            {item.label === 'Mute Channel' && <i className={`fa-solid fa-bell ${styles.menuIcon}`}></i>}
            {item.label === 'Invite to Channel' && <i className={`fa-solid fa-user-plus ${styles.menuIcon}`}></i>}
            {item.label === 'Delete Channel' && <i className={`fa-solid fa-trash ${styles.menuIcon} ${styles.deleteIcon}`}></i>}
            

            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarContextMenuView;