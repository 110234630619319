const startNewDM = async (space_id, user_id, receiver_id, dm_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}start-new-dm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ space_id, user_id, receiver_id, dm_id })
    });

    if (!response.ok) {
      throw new Error("Failed to start a new DM");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error starting new DM in service:", error);
    return null;
  }
};

const fetchDMList = async (space_id, user_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${space_id}/${user_id}/fetch-dms`);
    
    if (!response.ok) {
      throw new Error("Failed to fetch user DM conversations");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching user DM conversations in service:", error);
    return null;
  }
};

const deleteDMConversation = async (space_id, user_id, dm_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}delete-dm-conversation`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ space_id, user_id, dm_id })
    });

    if (!response.ok) {
      throw new Error("Failed to hide DM conversation");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error hiding DM conversation in service:", error);
    return null;
  }
};

const getDMs = async (space_id, dm_id, before, limit) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}get-dms/${space_id}/${dm_id}?before=${before}&limit=${limit}`);
    
    if (!response.ok) {
      throw new Error("Failed to fetch DMs");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching DMs in service:", error);
    return null;
  }
};

const deleteDMMessage = async (dm_id, message_id, created_at) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}dms/${dm_id}/messages/${message_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ created_at })
    });

    if (!response.ok) {
      throw new Error("Failed to delete DM message");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error deleting DM message in service:", error);
    return null;
  }
};

export { startNewDM, getDMs, fetchDMList, deleteDMMessage, deleteDMConversation };