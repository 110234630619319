const API_BASE_URL = process.env.REACT_APP_API_URL;

export const uploadPublicIdentityKey = async (userId, workspaceId, publicKey) => {
  try {
    const response = await fetch(`${API_BASE_URL}keys/identity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, workspaceId, publicKey }),
    });
    if (!response.ok) throw new Error('Failed to upload public identity key');
    return await response.json();
  } catch (error) {
    console.error('Error uploading public identity key:', error);
    throw error;
  }
};

export const uploadPublicSignedPreKey = async (userId, workspaceId, keyId, publicKey, signature) => {
  try {
    const response = await fetch(`${API_BASE_URL}keys/signed-prekey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, workspaceId, keyId, publicKey, signature }),
    });
    if (!response.ok) throw new Error('Failed to upload public signed pre-key');
    return await response.json();
  } catch (error) {
    console.error('Error uploading public signed pre-key:', error);
    throw error;
  }
};

export const uploadPublicOneTimePreKey = async (userId, workspaceId, keyId, publicKey) => {
  try {
    const response = await fetch(`${API_BASE_URL}keys/onetime-prekey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, workspaceId, keyId, publicKey }),
    });
    if (!response.ok) throw new Error('Failed to upload public one-time pre-key');
    return await response.json();
  } catch (error) {
    console.error('Error uploading public one-time pre-key:', error);
    throw error;
  }
};