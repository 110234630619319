const fetchSpaceMembers = async (spaceId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceId}/members`);
    if (!response.ok) {
      throw new Error('Failed to fetch space members');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching space members:', error);
    throw error;
  }
};

const fetchDMwithUser = async (space_id, user_id, receiver_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${space_id}/${user_id}/${receiver_id}/fetch-dm-with-user`);
    
    if (!response.ok) {
      throw new Error("Failed to fetch user DM conversations");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching user DM conversations in service:", error);
    return null;
  }
};

const fetchGroupChatsWithUsers = async (space_id, user_id, member_ids) => {
  try {
    const memberIdsString = Array.isArray(member_ids) ? member_ids.join(',') : member_ids;
    const response = await fetch(`${process.env.REACT_APP_API_URL}${space_id}/${user_id}/${memberIdsString}/fetch-group-chats-with-users`);
    
    if (!response.ok) {
      throw new Error("Failed to fetch group chats with users");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching group chats with users in service:", error);
    return null;
  }
};

export { fetchSpaceMembers, fetchDMwithUser, fetchGroupChatsWithUsers };
