const sendLoginLink = async (email) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    return response.ok;
  } catch (error) {
    console.error('Error sending login link:', error);
    return false;
  }
};

async function logout() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}auth/logout`, {
    method: 'POST',
    credentials: 'include',
  });

  return response.ok;
}

const verifyToken = async (code) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}auth/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ code }),
    });

    return response.ok;
  } catch (error) {
    console.error('Error verifying token:', error);
    return false;
  }
};

const initiateGoogleLogin = async (redirectUrl) => {
  try {
    const state = redirectUrl ? encodeURIComponent(redirectUrl) : '';
    window.location.href = `${process.env.REACT_APP_API_URL}auth/google?prompt=select_account&state=${state}`;
  } catch (error) {
    console.error('Error initiating Google login:', error);
    return false;
  }
};

export { sendLoginLink, logout, verifyToken, initiateGoogleLogin };