import { initializeKeyStore } from '../utils/indexedDB';

// Store Workspace Identity Key
async function storeWorkspaceIdentityKey(userId, workspaceId, identityKey) {
  const keyStore = await initializeKeyStore();
  await keyStore.setWorkspaceKey(userId, workspaceId, 'identity', identityKey);
}

// Store Workspace Signed Pre-Key
async function storeWorkspaceSignedPreKey(userId, workspaceId, signedPreKey) {
  const keyStore = await initializeKeyStore();
  await keyStore.setWorkspaceKey(userId, workspaceId, 'signed_prekey', signedPreKey);
}

// Store Workspace One-Time Pre-Keys
async function storeWorkspaceOneTimePreKeys(userId, workspaceId, oneTimePreKeys) {
  const keyStore = await initializeKeyStore();
  await keyStore.setWorkspaceKey(userId, workspaceId, 'onetime_prekeys', oneTimePreKeys);
}

// Store Workspace Shared Key
async function storeWorkspaceSharedKey(userId, workspaceId, sharedKey) {
  const keyStore = await initializeKeyStore();
  await keyStore.setWorkspaceKey(userId, workspaceId, 'workspace_shared_key', sharedKey);
}

// Retrieve Workspace Identity Key
async function getWorkspaceIdentityKey(userId, workspaceId) {
  const keyStore = await initializeKeyStore();
  const result = await keyStore.getWorkspaceKey(userId, workspaceId, 'identity');
  return result ? result.value : null;
}

// Retrieve Workspace Signed Pre-Key
async function getWorkspaceSignedPreKey(userId, workspaceId) {
  const keyStore = await initializeKeyStore();
  const result = await keyStore.getWorkspaceKey(userId, workspaceId, 'signed_prekey');
  return result ? result.value : null;
}

// Retrieve Workspace One-Time Pre-Keys
async function getWorkspaceOneTimePreKeys(userId, workspaceId) {
  const keyStore = await initializeKeyStore();
  const result = await keyStore.getWorkspaceKey(userId, workspaceId, 'onetime_prekeys');
  return result ? result.value : null;
}

// Retrieve Workspace Shared Key
async function getWorkspaceSharedKey(userId, workspaceId) {
  const keyStore = await initializeKeyStore();
  const result = await keyStore.getWorkspaceKey(userId, workspaceId, 'workspace_shared_key');
  return result ? result.value : null;
}

export {
  storeWorkspaceIdentityKey,
  storeWorkspaceSignedPreKey,
  storeWorkspaceOneTimePreKeys,
  getWorkspaceIdentityKey,
  getWorkspaceSignedPreKey,
  getWorkspaceOneTimePreKeys,
  storeWorkspaceSharedKey,
  getWorkspaceSharedKey
};