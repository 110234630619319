import React from 'react';
import styles from './TimestampLine.module.css';

const TimestampLine = ({ date }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className={styles.timestampLine}>
      <div className={styles.line}></div>
      <span className={styles.date}>{formatDate(date)}</span>
      <div className={styles.line}></div>
    </div>
  );
};

export default TimestampLine;