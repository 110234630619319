import React from 'react';
import styles from './UserQuickView.module.css';
import useUIStore from './useUIStore';
import useUserDetailsStore from '../../store/userDetailsStore';

const UserQuickView = ({ userDetails, onFullProfileClick, position }) => {
  const { setHoveringMenu } = useUIStore();
  const getUserStatus = useUserDetailsStore(state => state.getUserStatus);
  const isOnline = getUserStatus(userDetails.userID) === 'online';

  return (
    <div 
      className={styles.quickView} 
      style={{ top: position.top, left: position.left }}
      onMouseEnter={() => setHoveringMenu(true)}
      onMouseLeave={() => setHoveringMenu(false)}
    >
      <div className={styles.avatarWrapper}>
        <img 
          src={userDetails.imageUrl} 
          alt={`${userDetails.displayName}'s avatar`} 
          className={styles.avatar} 
        />
        <div className={styles.info}>
          <h3>{userDetails.displayName}</h3>
          <div className={styles.statusContainer}>
            <span>{isOnline ? 'Active' : 'Away'}</span>
            <div className={`${styles.statusIndicator} ${isOnline ? styles.online : styles.away}`} />
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <button onClick={() => {}}>Message</button>
        <button onClick={onFullProfileClick}>View Profile</button>
      </div>
    </div>
  );
};

export default UserQuickView;