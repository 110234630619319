import { create } from 'zustand';

const useSettingsStore = create((set) => ({
  isSettingsModalOpen: false,
  selectedSection: 'account',
  setIsSettingsModalOpen: (isOpen) => set({ isSettingsModalOpen: isOpen }),
  setSelectedSection: (section) => set({ selectedSection: section }),
}));

export default useSettingsStore;
