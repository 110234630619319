import React, { useEffect, useState } from 'react';
import SidebarView from '../features/sidebar/components/structure/SidebarView';
import Spinner from '../features/Spinner/Spinner';
import useUserDetailsStore from '../store/userDetailsStore';
import useSidebarStore from '../store/sidebarStore';
import useAuthStore from '../store/authStore';
import DMCreationFlow from '../features/chat/components/DMCreationFlow/DMCreationFlow';
import MainPanelFeed from '../features/chat/components/MainPanel/MainPanelFeed';

import { fetchLatestRoomActivity, fetchLatestMessagesForSpace } from '../features/sidebar/services/roomActivityService';
import styles from './HomeView.module.css';

const HomeView = () => {
  const initializeSocket = useSidebarStore(state => state.initializeSocket);
  const isDataLoaded = useSidebarStore(state => state.isDataLoaded);
  const fetchFoldersForSpace = useSidebarStore(state => state.fetchFoldersForSpace);
  const fetchDMConversations = useSidebarStore(state => state.fetchDMConversations);
  const fetchGroupChats = useSidebarStore(state => state.fetchGroupChats);
  const fetchSpaceDetails = useSidebarStore(state => state.fetchSpaceDetails);
  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);
  const fetchLastActiveRoom = useSidebarStore(state => state.fetchLastActiveRoom);
  const isDMCreationFlowActive = useSidebarStore(state => state.isDMCreationFlowActive);

  const spaceId = useAuthStore(state => state.spaceID);
  const userID = useAuthStore(state => state.userID);

  const socket = useSidebarStore(state => state.socket);
  const [isSocketReady, setIsSocketReady] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    initializeSocket();
  }, [initializeSocket]);

  useEffect(() => {
    if (socket) {
      setIsSocketReady(true);
    }
  }, [socket]);

  useEffect(() => {
    if (isSocketReady && !dataFetched) { 
      const fetchAllData = async () => {
        try {
          // Fetch initial data
          await Promise.all([
            fetchFoldersForSpace(spaceId),
            fetchSpaceDetails(spaceId),
            fetchUserDetails(userID, spaceId),
            fetchDMConversations(spaceId, userID),
            fetchGroupChats(spaceId, userID),
            fetchLastActiveRoom(userID, spaceId)
          ]);

          // Get read status and latest messages in parallel
          const [lastOpenedRoomTimestamps, lastSentRoomMsgs] = await Promise.all([
            fetchLatestRoomActivity(spaceId, userID),
            fetchLatestMessagesForSpace(spaceId, userID)   
          ]);

          // Compare timestamps and set unread state 
          const unreadRooms = new Set(
            lastOpenedRoomTimestamps.filter(status => {
              const latestMessage = lastSentRoomMsgs[status.room_id];
              return latestMessage && new Date(latestMessage) > new Date(status.last_read_at);
            }).map(s => s.room_id)
          );

          useSidebarStore.setState({
            unreadRooms,
            lastReadTimestamps: lastOpenedRoomTimestamps.reduce((acc, s) => ({
              ...acc,
              [s.room_id]: s.last_read_at
            }), {})
          });

          setDataFetched(true);
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error;
        }
      };
      fetchAllData(); 
    }
  }, [isSocketReady, dataFetched, fetchFoldersForSpace, fetchSpaceDetails, fetchUserDetails, fetchDMConversations, fetchLastActiveRoom, fetchGroupChats, spaceId, userID]);

  if (!isSocketReady || !isDataLoaded.folderContents || !dataFetched) {
    return <Spinner />;
  }
 
  return (
    <div className={styles.container}>
      <SidebarView />
      {isDMCreationFlowActive ? <DMCreationFlow /> : <MainPanelFeed />}
    </div>
  );
};

export { HomeView };