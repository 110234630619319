import React from 'react';
import styles from './WelcomeMessage.module.css';

const WelcomeMessage = ({ channelName }) => {
  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.welcomeHeader}>
        <span className={styles.hashIcon}>#</span>
        <h2>Welcome to {channelName}!</h2>
      </div>
      <p className={styles.welcomeText}>
        This is the start of the #{channelName} channel. 
        Send messages, share files, and get work done together.
      </p>
    </div>
  );
};

export default WelcomeMessage;