import React, { useState, useEffect } from 'react';
import styles from '../ChatSection.module.css';
import useSidebarStore from '../../../../store/sidebarStore';
import useUserDetailsStore from '../../../../store/userDetailsStore';

const TopBarView = () => {
  const { currentRoom, channelDetails, folderContents, folders } = useSidebarStore();
  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    const loadUserDetails = async () => {
      if (currentRoom.room_type === 'dm' && currentRoom.receiver_id) {
        const details = await fetchUserDetails(currentRoom.receiver_id);
        if (details?.userSpaceDetails) {
          setDisplayName(details.userSpaceDetails.user_display_name || "Unknown User");
        } else {
          setDisplayName("Loading...");
        }
      } else if (currentRoom.room_type === 'group_chat') {
        const groupChat = useSidebarStore.getState().groupChats
          .find(chat => chat.group_id === currentRoom.room_id);

        if (groupChat?.member_ids) {
          const memberDetails = await Promise.all(
            groupChat.member_ids.map(id => fetchUserDetails(id))
          );
          
          const names = memberDetails
            .filter(detail => detail?.userSpaceDetails)
            .map(detail => detail.userSpaceDetails.user_display_name)
            .filter(name => name);

          setDisplayName(names.length > 0 ? names.join(', ') : 'Loading...');
        }
      }
    };

    loadUserDetails();
  }, [currentRoom, fetchUserDetails]);

  const getFolderName = () => {
    for (const [folderId, channels] of Object.entries(folderContents)) {
      if (channels.some(channel => channel.channel_id === currentRoom.room_id)) {
        const folder = folders.find(f => f.folder_id === folderId);
        return folderId === 'SIDEBAR' ? 'Workspace' : folder?.folder_name || 'Unknown';
      }
    }
    return 'Unknown';
  };

  return (
    <div className={styles.topBar}>
      <h2 className={styles.channelName}>
        {currentRoom.room_type === 'channel' ? (
          <>
            <span className={styles.folderName}>{getFolderName()}</span>
            <span className={styles.separator}>|</span>
            {channelDetails[currentRoom.room_id]?.channel_name}
          </>
        ) : (
          <span className={styles.chatName}>{displayName}</span>
        )}
      </h2>
      <div className={styles.topBarIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5M4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12m0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7m7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44M15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35"/>
        </svg>
      </div>
    </div>
  );
};

export default TopBarView;