import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RootView, LoginView, LogoutView, HomeView, NotFoundView, PrivacyPolicyView } from './pages';
import { SpaceDetailsStep, UserDetailsStep, InviteUsersStep } from './features/onboarding/components';

import { ProtectedRoute } from './features/authentication'
import AuthInitializer from './features/authentication/components/AuthInitializer';
import { SpaceRouteWrapper } from './components/SpaceRouteWrapper';
import JoinSpaceView from './features/onboarding/components/JoinSpaceView';
import MobileBlocker from './components/MobileBlocker';

export default function App() {
  return (
    <MobileBlocker>
      <AuthInitializer>
        <Router>
          <Routes>
            <Route path="/" element={<RootView />} />

            <Route path="/login" element={<LoginView />} />
            <Route path="/logout" element={<LogoutView />} />
            <Route path="/privacy" element={<PrivacyPolicyView />} />

            <Route path="/create/space" element={<ProtectedRoute requiredStatus="create/space"><SpaceDetailsStep/></ProtectedRoute>} />
            <Route path="/create/profile" element={<ProtectedRoute requiredStatus="create/profile"><UserDetailsStep/></ProtectedRoute>} />
            <Route path="/create/invites" element={<ProtectedRoute requiredStatus="create/invites"><InviteUsersStep /></ProtectedRoute>} />
            <Route path="/invite/:joinCode" element={<JoinSpaceView />} />

            <Route path="/client/:spaceID?" element={
              <ProtectedRoute requiredStatus="completed">
                <SpaceRouteWrapper>
                  <HomeView />
                </SpaceRouteWrapper>
              </ProtectedRoute>
            } />
            
            <Route path="*" element={<ProtectedRoute requiredStatus="completed"><NotFoundView /></ProtectedRoute>} />
          </Routes>
        </Router>
      </AuthInitializer>
    </MobileBlocker>
  );
}