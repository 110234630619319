import React, { useEffect } from 'react';
import useAuthStore from '../../../store/authStore';
import { Navigate } from 'react-router-dom'; 
import { updateLastActiveSpace } from '../../onboarding/services/onboardingService';

const ROUTE_ORDER = {
  '/': 0,
  'create/space': 1,
  'create/profile': 2,
  'create/invites': 3,
  'completed': 4
};

const ProtectedRoute = ({ children, requiredStatus }) => {
  const { isAuthenticated, onboardingStatus, userRole, spaceID, establishUserSpace, setOnboardingStatus, userEmail } = useAuthStore();

  useEffect(() => {
    const resetSpace = async () => {
      if (requiredStatus.startsWith('create/') && spaceID && !onboardingStatus) {
        try {
          await updateLastActiveSpace(userEmail, '');
            establishUserSpace('', '');
            setOnboardingStatus('create/space');
        } catch (error) {
          console.error('Error resetting space:', error);
        }
      }
    };

    resetSpace(); 
  }, [requiredStatus, spaceID, onboardingStatus, establishUserSpace, setOnboardingStatus, userEmail]);

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // If user is completed, they should only access completed routes or root
  if (onboardingStatus === 'completed') {
    if (requiredStatus !== 'completed') {
      return <Navigate to={`/client/${spaceID}`} replace />;
    }
    return children;
  }

  // Prevent members from accessing admin-only routes
  if (userRole === 'member' && ['create/space', 'create/invites'].includes(requiredStatus)) {
    return <Navigate to={`/client/${spaceID}`} replace />;
  }

  // For onboarding routes, strictly enforce order
  if (requiredStatus.startsWith('create/')) {
    // Must start with create/space
    if (!onboardingStatus && requiredStatus !== 'create/space') {
      return <Navigate to="/create/space" replace />;
    }

    // Prevent skipping steps
    if (ROUTE_ORDER[requiredStatus] > ROUTE_ORDER[onboardingStatus]) {
      return <Navigate to={`/${onboardingStatus}`} replace />;
    }

    // Prevent going backwards
    if (ROUTE_ORDER[requiredStatus] < ROUTE_ORDER[onboardingStatus]) {
      return <Navigate to={`/${onboardingStatus}`} replace />;
    }
  }

  return children;
};

export { ProtectedRoute };

