import React, { useEffect, useRef, useState, useCallback } from 'react';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import useSidebarStore from '../../../store/sidebarStore';
import MessageView from '../../Message/MessageView';
import styles from './ChatSection.module.css';
import ThreadSidebar from './ThreadSidebar';
import TypingIndicatorView from '../../TypingIndicator/TypingIndicatorView';
import TextBoxView from '../../TextBox/TextBoxView';
import WelcomeMessage from './WelcomeMessage/WelcomeMessage';
import TimestampLine from './TimestampLine/TimestampLine';

const ChatSectionView = () => { 
  const { currentRoom, messages, fetchMessages, socket, channelDetails } = useSidebarStore(); 
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isThreadSidebarOpen, setIsThreadSidebarOpen] = useState(false);
  const [selectedParentMessage, setSelectedParentMessage] = useState(null);
  const [parentRoomType, setParentRoomType] = useState(null);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  const loadInitialMessages = useCallback(async () => {
    setIsLoading(true);
    await fetchMessages(currentRoom.room_id, currentRoom.room_type, new Date().toISOString(), 25);
    setIsLoading(false);
    setIsInitialLoad(false);
    scrollToBottom();
  }, [currentRoom.room_id, currentRoom.room_type, fetchMessages]);

  const handleScroll = useCallback(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer || isLoading) return;

    const { scrollTop } = chatContainer;

    const loadMoreMessages = async () => {
      const roomMessages = messages[currentRoom.room_id] || [];
      if (roomMessages.length > 0) {
        setIsLoading(true);
        const oldestMessage = roomMessages[0];
        const previousScrollHeight = chatContainer.scrollHeight;
        
        await fetchMessages(currentRoom.room_id, currentRoom.room_type, oldestMessage.created_at, 25);
        
        const updatedMessages = messages[currentRoom.room_id] || [];
        const newMessageCount = updatedMessages.length - roomMessages.length;

        if (newMessageCount < 25) {
          setHasReachedEnd(true);
        }
        
        requestAnimationFrame(() => {
          const newScrollHeight = chatContainer.scrollHeight;
          const scrollDiff = newScrollHeight - previousScrollHeight;
          chatContainer.scrollTop = scrollDiff;
        });
        
        setIsLoading(false);
      } else {
        setHasReachedEnd(true);
      }
    };

    if (scrollTop <= 100 && messages[currentRoom.room_id]?.length) {
      loadMoreMessages();
    }
  }, [currentRoom.room_id, currentRoom.room_type, fetchMessages, isLoading, messages]);

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    if (currentRoom.room_id) {
      loadInitialMessages();
      if (socket) {
        socket.emit('join_room', currentRoom.room_id, currentRoom.room_type);
      }
    }
  }, [currentRoom.room_id, currentRoom.room_type, socket, loadInitialMessages]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
      return () => chatContainer.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (!isInitialLoad && !isLoading && chatContainerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = chatContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
      
      if (isNearBottom) {
        scrollToBottom();
      }
    }
  }, [messages, isInitialLoad, isLoading]);

  const [hasLeftThread, setHasLeftThread] = useState(false);

  const handleMessageClick = async (message) => {
    // If the user has left this thread, allow them to rejoin
    if (selectedParentMessage?.message_id === message.message_id && hasLeftThread) {
      setHasLeftThread(false);
    }
  
    // If the user is trying to join a different thread room or has left the current thread
    if (selectedParentMessage?.message_id && selectedParentMessage.message_id !== message.message_id) {
      socket.emit('leave_room', selectedParentMessage.message_id);
      setHasLeftThread(true); // Mark that the user left the previous thread
    }
  
    // Set the new thread's parent message and open the thread sidebar
    setSelectedParentMessage(message);
    setParentRoomType(currentRoom.room_type);
    setIsThreadSidebarOpen(true);
  
    // Fetch the thread's messages and join the new thread room
    await fetchMessages(message.message_id, 'thread', new Date().toISOString(), 25);
    socket.emit('join_room', message.message_id, 'thread');
    setHasLeftThread(false); // Reset the flag once the user joins the room
  };

  const handleCloseSidebar = () => {
    socket.emit('leave_room', selectedParentMessage.message_id);
    setIsThreadSidebarOpen(false);
  };

  useEffect(() => {
    if (isThreadSidebarOpen) {
      setIsThreadSidebarOpen(false);
      setSelectedParentMessage(null);
      setParentRoomType(null);
      if (socket && selectedParentMessage) {
        socket.emit('leave_room', selectedParentMessage.message_id);
      }
    }
  }, [currentRoom.room_id]);

  const shouldNestMessage = (currentMsg, prevMsg) => {
    if (!prevMsg || currentMsg.sender_id !== prevMsg.sender_id) {
      return false;
    }
    
    const currentTime = new Date(currentMsg.created_at);
    const prevTime = new Date(prevMsg.created_at);
    const timeDiff = (currentTime - prevTime) / (1000 * 60);  
    
    return timeDiff <= 5;
  };

  const renderMessages = () => {
    const messageList = messages[currentRoom.room_id] || [];
    const renderedElements = [];
    let currentDate = null;

    messageList.forEach((message, index) => {
      const messageDate = new Date(message.created_at);
      const messageDay = new Date(messageDate.getFullYear(), messageDate.getMonth(), messageDate.getDate());

      // Check if we need to add a new timestamp line
      if (!currentDate || messageDay.getTime() !== currentDate.getTime()) {
        currentDate = messageDay;
        renderedElements.push(
          <TimestampLine 
            key={`timestamp-${messageDay.getTime()}`} 
            date={messageDay} 
          />
        );
      }

      const prevMessage = index > 0 ? messageList[index - 1] : null;
      const isNested = shouldNestMessage(message, prevMessage);

      renderedElements.push(
        <MessageView 
          key={message.message_id} 
          message={message} 
          onClick={() => handleMessageClick(message)}
          isInThreadSidebarOpen={false}
          isNested={isNested}
          showHeader={!isNested}
        />
      );
    });

    // Only show welcome message if we've reached the end of message history AND we're not loading
    if (hasReachedEnd && !isLoading && currentRoom.room_type === 'channel') {
      renderedElements.unshift(
        <WelcomeMessage 
          key="welcome-message"
          channelName={channelDetails[currentRoom.room_id]?.channel_name || 'channel'} 
        />
      );
    }

    return renderedElements;
  };

  // Reset hasReachedEnd when changing rooms
  useEffect(() => {
    setHasReachedEnd(false);
  }, [currentRoom.room_id]);

  return (
    <PanelGroup direction="horizontal">
      <Panel minSize={50}>
        <div className={styles.chatWrapper}>
          <div className={styles.chatContainer} ref={chatContainerRef}>
            <div className={styles.messagesWrapper}>
              {isLoading && <div className={styles.loadingIndicator}>Loading...</div>}
              {renderMessages()}
            </div>
          </div>
          <TypingIndicatorView />
          <TextBoxView 
            roomId={currentRoom.room_id} 
            roomType={currentRoom.room_type} 
            threadInRoomType={null} 
            parentMessageId={null} 
            parentCreatedAt={null}
          />
        </div>
      </Panel>
      {isThreadSidebarOpen && (
        <>
          <PanelResizeHandle className={styles.resizeHandle} />
          <Panel defaultSize={30} minSize={15} maxSize={70}>
            <ThreadSidebar
              isOpen={isThreadSidebarOpen}
              onClose={handleCloseSidebar}
              parentMessage={selectedParentMessage}
              parentRoomType={parentRoomType}
              parentRoomId={currentRoom.room_id}
              parentCreatedAt={selectedParentMessage?.created_at}
            />
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};

export default ChatSectionView;