const channelService = require("./channelService");
const folderService = require("./folderService");
const spaceService = require("./spaceService");
const roomActivityService = require("./roomActivityService");

module.exports = {
  getChannelDetails: channelService.getChannelDetails,
  getChannelMessages: channelService.getChannelMessages, 
  deleteChannelMessage: channelService.deleteChannelMessage,
  
  getFolderContents: folderService.getFolderContents,
  createChannel: folderService.createChannel,
  deleteChannel: folderService.deleteChannel,

  getFolders: spaceService.getFolders,
  changeFolderOrder: spaceService.changeFolderOrder,
  getSpaceDetails: spaceService.getSpaceDetails,
  getLastActiveRoom: spaceService.getLastActiveRoom,
  updateLastActiveRoom: spaceService.updateLastActiveRoom,

  fetchLatestRoomActivity: roomActivityService.fetchLatestRoomActivity,
  updateLatestRoomActivity: roomActivityService.updateLatestRoomActivity,
};
